<template>
	<div>
		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(215, 'Waste type')" :state="!$v.dataToSend.type.$error">
					<b-form-select v-model="$v.dataToSend.type.$model" :options="wasteTypeOptions"
						:state="!$v.dataToSend.type.$error" :disabled="issetDisabled" @change="handleChangeType" />
					<div v-if="$v.dataToSend.type.$error" class="invalid-feedback">
						{{ FormMSG(415, 'Waste type is required') }}
					</div>
				</b-form-group>
			</b-col>
			<!--
				commented by: Lanja
				Reason: https://app.clickup.com/t/86bwuxqxh

				<b-col sm="3" md="3" lg="3" xl="3" class="pt-4">
				<b-form-checkbox v-model="dataToSend.boolValue" switch :value="true" :unchecked-value="false" size="lg" :disabled="disableAll">
					<div class="d-flex align-items-center fs-14">{{ FormMSG(298, 'Waste sorted') }}</div>
				</b-form-checkbox>
			</b-col>-->
		</b-row>
		<!-- Standard waste-->
		<b-collapse :visible="dataToSend.type === 1 && hideFields === false">
			<b-row v-if="dataToSend.type === 1 && hideFields === false">
				<b-col sm="12" md="4" lg="4" xl="4">
					<div v-if="$screen.width > 576">
						<b-form-group :label="FormMSG(16, 'Cardboard')">
							<b-input-group>
								<b-form-input v-model="dataToSend.kgCardboardWaste" :isDisable="issetDisabled"
									:is-for-number="false" :addUnit="true" @change="emitChange" />
								<b-input-group-append>
									<b-input-group-text class="bg-color-north-wind text-color-white">
										{{ wasteUnitsData[currentProject.cardboardUnit].text }}
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</div>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(16, 'Cardboard')">
								<b-form-input v-model="dataToSend.kgCardboardWaste" :disabled="issetDisabled"
									:class="{ 'bg-color-grams-hair': issetDisabled }" @change="emitChange" />
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700">{{ wasteUnitsData[currentProject.cardboardUnit].text }}</div>
						</div>
					</div>
				</b-col>
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group v-if="$screen.width > 576" :label="FormMSG(18, 'Paper')">
						<b-input-group>
							<b-form-input v-model="dataToSend.kgPaperWaste" :isDisable="issetDisabled"
								:is-for-number="false" :addUnit="true" @change="emitChange" />
							<b-input-group-append>
								<b-input-group-text class="bg-color-north-wind text-color-white">
									{{ wasteUnitsData[currentProject.paperUnit].text }}
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(18, 'Paper')">
								<b-form-input v-model="dataToSend.kgPaperWaste" :disabled="issetDisabled"
									:class="{ 'bg-color-grams-hair': issetDisabled }" @change="emitChange" />
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700">{{ wasteUnitsData[currentProject.paperUnit].text }}</div>
						</div>
					</div>
				</b-col>
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group v-if="$screen.width > 576" :label="FormMSG(19, 'Plastic')">
						<b-input-group>
							<b-form-input v-model="dataToSend.kgPlasticWaste" :isDisable="issetDisabled"
								:is-for-number="false" :addUnit="true" @change="emitChange" />
							<b-input-group-append>
								<b-input-group-text class="bg-color-north-wind text-color-white">
									{{ wasteUnitsData[currentProject.plasticUnit].text }}
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(19, 'Plastic')">
								<b-form-input v-model="dataToSend.kgPlasticWaste" :disabled="issetDisabled"
									:class="{ 'bg-color-grams-hair': issetDisabled }" @change="emitChange" />
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700">{{ wasteUnitsData[currentProject.plasticUnit].text }}</div>
						</div>
					</div>
				</b-col>
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group v-if="$screen.width > 576" :label="FormMSG(20, 'Food')">
						<b-input-group>
							<b-form-input v-model="dataToSend.kgFoodWaste" :isDisable="issetDisabled"
								:is-for-number="false" :addUnit="true" @change="emitChange" />
							<b-input-group-append>
								<b-input-group-text class="bg-color-north-wind text-color-white">
									{{ wasteUnitsData[currentProject.foodUnit].text }}
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(20, 'Food')">
								<b-form-input v-model="dataToSend.kgFoodWaste" :disabled="issetDisabled"
									:class="{ 'bg-color-grams-hair': issetDisabled }" @change="emitChange" />
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700">{{ wasteUnitsData[currentProject.foodUnit].text }}</div>
						</div>
					</div>
				</b-col>
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group v-if="$screen.width > 576" :label="FormMSG(21, 'General waste')">
						<b-input-group>
							<b-form-input v-model="dataToSend.kgGeneralWaste" :isDisable="issetDisabled"
								:is-for-number="false" :addUnit="true" @change="emitChange" />
							<b-input-group-append>
								<b-input-group-text class="bg-color-north-wind text-color-white">
									{{ wasteUnitsData[currentProject.generalWasteUnit].text }}
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(21, 'General waste')">
								<b-form-input v-model="dataToSend.kgGeneralWaste" :disabled="issetDisabled"
									:class="{ 'bg-color-grams-hair': issetDisabled }" @change="emitChange" />
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700">{{ wasteUnitsData[currentProject.generalWasteUnit].text }}</div>
						</div>
					</div>
				</b-col>
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group v-if="$screen.width > 576" :label="FormMSG(23, 'Glass waste')">
						<b-input-group>
							<b-form-input v-model="dataToSend.kgGlassWaste" :isDisable="issetDisabled"
								:is-for-number="false" :addUnit="true" @change="emitChange" />
							<b-input-group-append>
								<b-input-group-text class="bg-color-north-wind text-color-white">
									{{ wasteUnitsData[currentProject.glassUnit].text }}
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(21, 'Glass waste')">
								<b-form-input v-model="dataToSend.kgGlassWaste" :disabled="issetDisabled"
									:class="{ 'bg-color-grams-hair': issetDisabled }" @change="emitChange" />
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700">{{ wasteUnitsData[currentProject.glassUnit].text }}</div>
						</div>
					</div>
				</b-col>
				<!--
				commented by: Lanja
				Reason: https://app.clickup.com/t/86bwuxqxh

				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group v-if="$screen.width > 576" :label="FormMSG(324, 'Batteries')">
						<b-form-input v-model="dataToSend.unitBatterieWaste" @change="emitChange" />
					</b-form-group>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(324, 'Batteries')">
								<b-form-input v-model="dataToSend.unitBatterieWaste" @change="emitChange" />
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700"></div>
						</div>
					</div>
				</b-col>-->
			</b-row>
		</b-collapse>
		<!-- Standard waste-->
		<!-- Set destruction waste-->
		<b-collapse :visible="dataToSend.type === 2 && hideFields === false">
			<b-row v-if="dataToSend.type === 2 && hideFields === false">
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group v-if="$screen.width > 576" :label="FormMSG(217, 'Paint/Special waste')">
						<b-input-group>
							<b-form-input 
								v-model="dataToSend.kgPaintWaste" 
								:isDisable="issetDisabled"
								:is-for-number="false" 
								:addUnit="true" 
								@change="emitChange" 
							/>
							<b-input-group-append>
								<b-input-group-text class="bg-color-north-wind text-color-white">
									{{ wasteUnitsData[currentProject.paintSpecialWasteUnit].text }}
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(217, 'Paint/Special waste')">
								<b-form-input 
									v-model="dataToSend.kgPaintWaste" 
									:disabled="issetDisabled"
									:class="{ 'bg-color-grams-hair': issetDisabled }" 
									@change="emitChange" 
									/>
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700">
								{{ wasteUnitsData[currentProject.paintSpecialWasteUnit].text }}
							</div>
						</div>
					</div>
				</b-col>
				<!--
				commented by: Lanja
				Reason: https://app.clickup.com/t/86bwuxqxh

				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group v-if="$screen.width > 576" :label="FormMSG(16, 'Cardboard')">
						<b-input-group>
							<b-form-input
								v-model="dataToSend.kgCardboardWaste"
								:disabled="disableAll"
								:class="{ 'bg-color-grams-hair': disableAll }"
								@change="emitChange"
							/>
							<b-input-group-append>
								<b-input-group-text class="bg-color-north-wind text-color-white">
									{{ FormMSG(17, 'kg') }}
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(16, 'Cardboard')">
								<b-form-input
									v-model="dataToSend.kgCardboardWaste"
									:disabled="disableAll"
									:class="{ 'bg-color-grams-hair': disableAll }"
									@change="emitChange"
								/>
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700">{{ FormMSG(29, 'kg') }}</div>
						</div>
					</div>
				</b-col>-->
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group v-if="$screen.width > 576" :label="FormMSG(219, 'Wood')">
						<b-input-group>
							<b-form-input 
								v-model="dataToSend.kgWoodWaste" 
								:isDisable="issetDisabled"
								:is-for-number="false" 
								:addUnit="true" 
								@change="emitChange" 
							/>
							<b-input-group-append>
								<b-input-group-text class="bg-color-north-wind text-color-white">
									{{ wasteUnitsData[currentProject.woodUnit].text }}
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(219, 'Wood')">
								<b-form-input 
									v-model="dataToSend.kgWoodWaste" 
									:disabled="issetDisabled"
									:class="{ 'bg-color-grams-hair': issetDisabled }" 
									@change="emitChange" 
								/>
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700">
								{{ wasteUnitsData[currentProject.woodUnit].text }}
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row v-if="dataToSend.type === 2 && hideFields === false">
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group v-if="$screen.width > 576" :label="FormMSG(220, 'Steel')">
						<b-input-group>
							<b-form-input 
								v-model="dataToSend.kgSteelWaste" 
								:isDisable="issetDisabled"
								:is-for-number="false" 
								:addUnit="true" 
								@change="emitChange" 
							/>
							<b-input-group-append>
								<b-input-group-text class="bg-color-north-wind text-color-white">
									{{ wasteUnitsData[currentProject.steelUnit].text }}
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(220, 'Steel')">
								<b-form-input 
									v-model="dataToSend.kgSteelWaste" 
									:disabled="issetDisabled"
									:class="{ 'bg-color-grams-hair': issetDisabled }" 
									@change="emitChange" 
									/>
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700">
								{{ wasteUnitsData[currentProject.steelUnit].text }}
							</div>
						</div>
					</div>
				</b-col>
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group v-if="$screen.width > 576" :label="FormMSG(19, 'Plastic')">
						<b-input-group>
							<b-form-input 
								v-model="dataToSend.kgPlasticWaste" 
								:isDisable="issetDisabled"
								:is-for-number="false" 
								:addUnit="true" 
								@change="emitChange" 
							/>
							<b-input-group-append>
								<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ wasteUnitsData[currentProject.plasticDestructionUnit].text }}
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
					<div v-else class="d-flex mt-3">
						<div class="w-90 mr-3">
							<b-form-group :label="FormMSG(19, 'Plastic')">
								<b-form-input 
									v-model="dataToSend.kgPlasticWaste" 
									:disabled="issetDisabled"
									:class="{ 'bg-color-grams-hair': issetDisabled }" 
									@change="emitChange" 
									/>
							</b-form-group>
						</div>
						<div class="d-flex align-items-center pt-3 text-capitalize">
							<div class="fs-20 fw-700">
								{{ wasteUnitsData[currentProject.plasticDestructionUnit].text }}
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-collapse>
		<!-- Set destruction waste-->
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';
import { validationMixin } from 'vuelidate';
import { minValue } from 'vuelidate/lib/validators';
import { store } from '@/store';
import CustomFormGroup from '@/components/Forms/CustomFormGroup';
import { WASTE_TYPE_MENU } from '../../../../../shared/constants';

export default {
	name: 'CoTwoWasteForm',

	mixins: [languageMessages, validationMixin],

	props: {
		coTwoData: { type: Object, default: () => ({}), required: false },
		hideFields: { type: Boolean, default: false, required: false },
		disableAll: { type: Boolean, default: false, required: false },
		disableForms: { type: Boolean, default: false, required: false }
	},

	components: {
		CustomFormGroup
	},

	data() {
		return {
			currentProject: store.getCurrentProjectConfig(),
			dataToSend: {
				type: 1,
				boolValue: false,
				kgCardboardWaste: 0,
				kgPaperWaste: 0,
				kgPlasticWaste: 0,
				kgFoodWaste: 0,
				kgGeneralWaste: 0,
				kgPaintWaste: 0,
				kgWoodWaste: 0,
				kgSteelWaste: 0,
				kgGlassWaste: 0
				// unitBatterieWaste: 0
			}
		};
	},

	validations: {
		dataToSend: {
			type: { minValue: minValue(1) }
		}
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (!_.isNil(newVal) && JSON.stringify(newVal) !== '{}') {
					this.dataToSend.type = newVal.type;
					this.dataToSend.boolValue = newVal.boolValue;

					this.dataToSend.kgCardboardWaste = newVal.kgCardboardWaste;
					this.dataToSend.kgPaperWaste = newVal.kgPaperWaste;
					this.dataToSend.kgPlasticWaste = newVal.kgPlasticWaste;
					this.dataToSend.kgFoodWaste = newVal.kgFoodWaste;
					this.dataToSend.kgGeneralWaste = newVal.kgGeneralWaste;
					this.dataToSend.kgPaintWaste = newVal.kgPaintWaste;
					this.dataToSend.kgWoodWaste = newVal.kgWoodWaste;
					this.dataToSend.kgSteelWaste = newVal.kgSteelWaste;
					this.dataToSend.kgGlassWaste = newVal.kgGlassWaste ? newVal.kgGlassWaste : 0;
					// this.dataToSend.unitBatterieWaste = newVal.unitBatterieWaste;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		wasteTypeOptions() {
			return [{ value: 0, text: this.FormMSG(912, 'Please select ...') }, ...this.FormMenu(WASTE_TYPE_MENU)];
		},
		issetDisabled() {
			return this.disableAll || this.disableForms;
		},
		wasteUnitsData() {
			return this.FormMenu(1452);
		}
	},

	methods: {
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		handleChangeType() {
			this.dataToSend.kgCardboardWaste = 0;
			this.dataToSend.kgPaperWaste = 0;
			this.dataToSend.kgPlasticWaste = 0;
			this.dataToSend.kgFoodWaste = 0;
			this.dataToSend.kgGeneralWaste = 0;
			this.dataToSend.kgPaintWaste = 0;
			this.dataToSend.kgWoodWaste = 0;
			this.dataToSend.kgSteelWaste = 0;
			this.dataToSend.kgGlassWaste = 0;
			// this.dataToSend.unitBatterieWaste = 0;

			this.emitChange();
		},
		emitChange() {
			this.dataToSend.kgCardboardWaste = parseFloat(this.dataToSend.kgCardboardWaste);
			this.dataToSend.kgPaperWaste = parseFloat(this.dataToSend.kgPaperWaste);
			this.dataToSend.kgPlasticWaste = parseFloat(this.dataToSend.kgPlasticWaste);
			this.dataToSend.kgFoodWaste = parseFloat(this.dataToSend.kgFoodWaste);
			this.dataToSend.kgGeneralWaste = parseFloat(this.dataToSend.kgGeneralWaste);
			this.dataToSend.kgPaintWaste = parseFloat(this.dataToSend.kgPaintWaste);
			this.dataToSend.kgWoodWaste = parseFloat(this.dataToSend.kgWoodWaste);
			this.dataToSend.kgSteelWaste = parseFloat(this.dataToSend.kgSteelWaste);
			this.dataToSend.kgGlassWaste = parseFloat(this.dataToSend.kgGlassWaste);
			// this.dataToSend.unitBatterieWaste = parseFloat(this.dataToSend.unitBatterieWaste);

			this.$emit('co-two-waste:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
