<template>
	<div>
		<b-row>
			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(318, 'Production rooms')" class="mb-0">
					<v-select
						v-model="$v.dataToSend.specialEquipmentId.$model"
						:options="listProductionRooms"
						:reduce="(option) => +option.id"
						label="name"
						:placeholder="FormMSG(52, 'Please select')"
						:disabled="disableAll"
						:class="{ 'is-invalid': $v.dataToSend.specialEquipmentId.$error }"
						@input="handleChangeType"
					/>
					<div v-if="$v.dataToSend.specialEquipmentId.$error" class="invalid-feedback">
						{{ FormMSG(589, 'Please select trailer') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';
import { getSpecialEquipmentList } from '@/cruds/special-equipment.crud';
import { SPE_PRODUCTION_ROOMS_TYPE, SPE_TRAILER_TYPE } from '../../../../../shared/constants';
import { validationMixin } from 'vuelidate';
import { minValue } from 'vuelidate/lib/validators';

export default {
	name: 'CoTwoProductionControlRoomForm',

	mixins: [languageMessages, validationMixin],

	props: {
		coTwoData: { type: Object, required: true },
		forCarbonTemplate: { type: Boolean, default: false, required: false },
		disableAll: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			hmcReasonSelected: 0,
			listProductionRooms: [],
			dataToSend: {
				specialEquipmentId: 0
			}
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.specialEquipmentId =
						newVal.specialEquipmentId === 0 || newVal.specialEquipmentId === null ? null : newVal.specialEquipmentId;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		hmcOptions() {
			return this.FormMenu(11008);
		}
	},

	async created() {
		await this.getSpecialEquipmentList();
	},

	validations: {
		dataToSend: {
			specialEquipmentId: { minValue: minValue(1) }
		}
	},

	methods: {
		async getSpecialEquipmentList() {
			this.listProductionRooms = await getSpecialEquipmentList({ type: SPE_PRODUCTION_ROOMS_TYPE, measurementOption: true });
		},
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		handleChangeType(payload) {
			this.dataToSend.specialEquipmentId = payload;
			this.emitChange();
		},
		emitChange() {
			this.$emit('co-two-production-control-room:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
