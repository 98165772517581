var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(49, "Activity type") } },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.activityTypeOptions,
                      state: _vm.getStateValidationField("dataToSend.type"),
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.$v.dataToSend.type.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.dataToSend.type, "$model", $$v)
                      },
                      expression: "$v.dataToSend.type.$model",
                    },
                  }),
                  _vm.$v.dataToSend.type.$error &&
                  _vm.getStateValidationField("dataToSend.type") !== null
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(251, "Please select activity type")
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(50, "Activity country") } },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.optionsForCountries,
                      state: _vm.getStateValidationField("dataToSend.subType"),
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.$v.dataToSend.subType.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.dataToSend.subType, "$model", $$v)
                      },
                      expression: "$v.dataToSend.subType.$model",
                    },
                  }),
                  _vm.$v.dataToSend.subType.$error &&
                  _vm.getStateValidationField("dataToSend.subType") !== null
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(252, "Please select activity country")
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(51, "Reporting type") } },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.reportingTypeOptions,
                      state: _vm.getStateValidationField(
                        "dataToSend.additionalSubType"
                      ),
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.$v.dataToSend.additionalSubType.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.dataToSend.additionalSubType,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.dataToSend.additionalSubType.$model",
                    },
                  }),
                  _vm.$v.dataToSend.additionalSubType.$error &&
                  _vm.getStateValidationField(
                    "dataToSend.additionalSubType"
                  ) !== null
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(253, "Please select reporting type")
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.additionalSubType === 2 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.additionalSubType === 2 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(52, "Post-production means"),
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.postProductionMeansOptions,
                                  state: _vm.getStateValidationField(
                                    "dataToSend.postProductionMeansType"
                                  ),
                                },
                                on: { change: _vm.emitChange },
                                model: {
                                  value:
                                    _vm.$v.dataToSend.postProductionMeansType
                                      .$model,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.$v.dataToSend.postProductionMeansType,
                                      "$model",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "$v.dataToSend.postProductionMeansType.$model",
                                },
                              }),
                              _vm.$v.dataToSend.postProductionMeansType
                                .$error &&
                              _vm.getStateValidationField(
                                "dataToSend.postProductionMeansType"
                              ) !== null
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              296,
                                              "Please select post-production means type"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  53,
                                  "Number of working days"
                                ),
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      state: _vm.getStateValidationField(
                                        "dataToSend.numberOfWorkingDays"
                                      ),
                                    },
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value:
                                        _vm.$v.dataToSend.numberOfWorkingDays
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataToSend.numberOfWorkingDays,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.dataToSend.numberOfWorkingDays.$model",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(54, "man-day")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.$v.dataToSend.numberOfWorkingDays.$error &&
                              _vm.getStateValidationField(
                                "dataToSend.numberOfWorkingDays"
                              ) !== null
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              265,
                                              "Please enter number of working days"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(
                                  55,
                                  "Number of extra-staff days"
                                ),
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      state: _vm.getStateValidationField(
                                        "dataToSend.numberOfExtraStaffDays"
                                      ),
                                    },
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value:
                                        _vm.$v.dataToSend.numberOfExtraStaffDays
                                          .$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataToSend
                                            .numberOfExtraStaffDays,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.dataToSend.numberOfExtraStaffDays.$model",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.FormMSG(54, "man-day")
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.$v.dataToSend.numberOfExtraStaffDays.$error &&
                              _vm.getStateValidationField(
                                "dataToSend.numberOfExtraStaffDays"
                              ) !== null
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              269,
                                              "Please enter number of extra-staff days"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }