var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(215, "Waste type"),
                    state: !_vm.$v.dataToSend.type.$error,
                  },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.wasteTypeOptions,
                      state: !_vm.$v.dataToSend.type.$error,
                      disabled: _vm.issetDisabled,
                    },
                    on: { change: _vm.handleChangeType },
                    model: {
                      value: _vm.$v.dataToSend.type.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.dataToSend.type, "$model", $$v)
                      },
                      expression: "$v.dataToSend.type.$model",
                    },
                  }),
                  _vm.$v.dataToSend.type.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(415, "Waste type is required")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible: _vm.dataToSend.type === 1 && _vm.hideFields === false,
          },
        },
        [
          _vm.dataToSend.type === 1 && _vm.hideFields === false
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "div",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(16, "Cardboard"),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          isDisable: _vm.issetDisabled,
                                          "is-for-number": false,
                                          addUnit: true,
                                        },
                                        on: { change: _vm.emitChange },
                                        model: {
                                          value:
                                            _vm.dataToSend.kgCardboardWaste,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dataToSend,
                                              "kgCardboardWaste",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "dataToSend.kgCardboardWaste",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            {
                                              staticClass:
                                                "bg-color-north-wind text-color-white",
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.wasteUnitsData[
                                                      _vm.currentProject
                                                        .cardboardUnit
                                                    ].text
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(16, "Cardboard"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair":
                                          _vm.issetDisabled,
                                      },
                                      attrs: { disabled: _vm.issetDisabled },
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgCardboardWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgCardboardWaste",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dataToSend.kgCardboardWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.wasteUnitsData[
                                        _vm.currentProject.cardboardUnit
                                      ].text
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(18, "Paper") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      isDisable: _vm.issetDisabled,
                                      "is-for-number": false,
                                      addUnit: true,
                                    },
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgPaperWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgPaperWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgPaperWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.wasteUnitsData[
                                                  _vm.currentProject.paperUnit
                                                ].text
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(18, "Paper") },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair":
                                          _vm.issetDisabled,
                                      },
                                      attrs: { disabled: _vm.issetDisabled },
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgPaperWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgPaperWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgPaperWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.wasteUnitsData[
                                        _vm.currentProject.paperUnit
                                      ].text
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(19, "Plastic") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      isDisable: _vm.issetDisabled,
                                      "is-for-number": false,
                                      addUnit: true,
                                    },
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgPlasticWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgPlasticWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgPlasticWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.wasteUnitsData[
                                                  _vm.currentProject.plasticUnit
                                                ].text
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(19, "Plastic"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair":
                                          _vm.issetDisabled,
                                      },
                                      attrs: { disabled: _vm.issetDisabled },
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgPlasticWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgPlasticWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgPlasticWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.wasteUnitsData[
                                        _vm.currentProject.plasticUnit
                                      ].text
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(20, "Food") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      isDisable: _vm.issetDisabled,
                                      "is-for-number": false,
                                      addUnit: true,
                                    },
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgFoodWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgFoodWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgFoodWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.wasteUnitsData[
                                                  _vm.currentProject.foodUnit
                                                ].text
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: _vm.FormMSG(20, "Food") } },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair":
                                          _vm.issetDisabled,
                                      },
                                      attrs: { disabled: _vm.issetDisabled },
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgFoodWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgFoodWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgFoodWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.wasteUnitsData[
                                        _vm.currentProject.foodUnit
                                      ].text
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(21, "General waste"),
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      isDisable: _vm.issetDisabled,
                                      "is-for-number": false,
                                      addUnit: true,
                                    },
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgGeneralWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgGeneralWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgGeneralWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.wasteUnitsData[
                                                  _vm.currentProject
                                                    .generalWasteUnit
                                                ].text
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(21, "General waste"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair":
                                          _vm.issetDisabled,
                                      },
                                      attrs: { disabled: _vm.issetDisabled },
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgGeneralWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgGeneralWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgGeneralWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.wasteUnitsData[
                                        _vm.currentProject.generalWasteUnit
                                      ].text
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            {
                              attrs: { label: _vm.FormMSG(23, "Glass waste") },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      isDisable: _vm.issetDisabled,
                                      "is-for-number": false,
                                      addUnit: true,
                                    },
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgGlassWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgGlassWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgGlassWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.wasteUnitsData[
                                                  _vm.currentProject.glassUnit
                                                ].text
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(21, "Glass waste"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair":
                                          _vm.issetDisabled,
                                      },
                                      attrs: { disabled: _vm.issetDisabled },
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgGlassWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgGlassWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgGlassWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.wasteUnitsData[
                                        _vm.currentProject.glassUnit
                                      ].text
                                    )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible: _vm.dataToSend.type === 2 && _vm.hideFields === false,
          },
        },
        [
          _vm.dataToSend.type === 2 && _vm.hideFields === false
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.FormMSG(217, "Paint/Special waste"),
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      isDisable: _vm.issetDisabled,
                                      "is-for-number": false,
                                      addUnit: true,
                                    },
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgPaintWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgPaintWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgPaintWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.wasteUnitsData[
                                                  _vm.currentProject
                                                    .paintSpecialWasteUnit
                                                ].text
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(
                                        217,
                                        "Paint/Special waste"
                                      ),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair":
                                          _vm.issetDisabled,
                                      },
                                      attrs: { disabled: _vm.issetDisabled },
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgPaintWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgPaintWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgPaintWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.wasteUnitsData[
                                          _vm.currentProject
                                            .paintSpecialWasteUnit
                                        ].text
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(219, "Wood") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      isDisable: _vm.issetDisabled,
                                      "is-for-number": false,
                                      addUnit: true,
                                    },
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgWoodWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgWoodWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgWoodWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.wasteUnitsData[
                                                  _vm.currentProject.woodUnit
                                                ].text
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(219, "Wood") },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair":
                                          _vm.issetDisabled,
                                      },
                                      attrs: { disabled: _vm.issetDisabled },
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgWoodWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgWoodWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgWoodWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.wasteUnitsData[
                                          _vm.currentProject.woodUnit
                                        ].text
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.dataToSend.type === 2 && _vm.hideFields === false
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(220, "Steel") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      isDisable: _vm.issetDisabled,
                                      "is-for-number": false,
                                      addUnit: true,
                                    },
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgSteelWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgSteelWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgSteelWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.wasteUnitsData[
                                                  _vm.currentProject.steelUnit
                                                ].text
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: { label: _vm.FormMSG(220, "Steel") },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair":
                                          _vm.issetDisabled,
                                      },
                                      attrs: { disabled: _vm.issetDisabled },
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgSteelWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgSteelWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgSteelWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.wasteUnitsData[
                                          _vm.currentProject.steelUnit
                                        ].text
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _vm.$screen.width > 576
                        ? _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(19, "Plastic") } },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      isDisable: _vm.issetDisabled,
                                      "is-for-number": false,
                                      addUnit: true,
                                    },
                                    on: { change: _vm.emitChange },
                                    model: {
                                      value: _vm.dataToSend.kgPlasticWaste,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.dataToSend,
                                          "kgPlasticWaste",
                                          $$v
                                        )
                                      },
                                      expression: "dataToSend.kgPlasticWaste",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-input-group-text",
                                        {
                                          staticClass:
                                            "bg-color-north-wind text-color-white",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                _vm.wasteUnitsData[
                                                  _vm.currentProject
                                                    .plasticDestructionUnit
                                                ].text
                                              ) +
                                              "\n\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c("div", { staticClass: "d-flex mt-3" }, [
                            _c(
                              "div",
                              { staticClass: "w-90 mr-3" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.FormMSG(19, "Plastic"),
                                    },
                                  },
                                  [
                                    _c("b-form-input", {
                                      class: {
                                        "bg-color-grams-hair":
                                          _vm.issetDisabled,
                                      },
                                      attrs: { disabled: _vm.issetDisabled },
                                      on: { change: _vm.emitChange },
                                      model: {
                                        value: _vm.dataToSend.kgPlasticWaste,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dataToSend,
                                            "kgPlasticWaste",
                                            $$v
                                          )
                                        },
                                        expression: "dataToSend.kgPlasticWaste",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-items-center pt-3 text-capitalize",
                              },
                              [
                                _c("div", { staticClass: "fs-20 fw-700" }, [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.wasteUnitsData[
                                          _vm.currentProject
                                            .plasticDestructionUnit
                                        ].text
                                      ) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }