var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c("include-elect", {
          attrs: {
            "parent-id": _vm.locationId,
            "for-create": _vm.forCreate,
            "edit-data": _vm.elecData,
            disabled: _vm.disableAll,
          },
          on: { change: _vm.handleChangeElect },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("include-gas", {
          attrs: {
            "parent-id": _vm.locationId,
            "for-create": _vm.forCreate,
            "edit-data": _vm.gasData,
            disabled: _vm.disableAll,
          },
          on: { change: _vm.handleChangeGas },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("include-water", {
          attrs: {
            "parent-id": _vm.locationId,
            "for-create": _vm.forCreate,
            "edit-data": _vm.waterData,
            disabled: _vm.disableAll,
          },
          on: { change: _vm.handleChangeWater },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("fuel-oil", {
          attrs: {
            "parent-id": _vm.locationId,
            "for-create": _vm.forCreate,
            "edit-data": _vm.generatorData,
            disabled: _vm.disableAll,
          },
          on: { change: _vm.handleChangeGenerator },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }