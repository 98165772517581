<template>
	<div>
		<b-row>
			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(18, 'Reason')" class="mb-0">
					<b-form-select
						v-model="dataToSend.type"
						:options="hmcOptions"
						:placeholder="FormMSG(52, 'Please select')"
						:disabled="disableAll"
						@change="handleChangeType"
					/>
				</b-form-group>
			</b-col>
			<!--
				commented by Lanja
				Reason: https://app.clickup.com/t/86bwtxefk
				
				<b-col v-if="[1, 2].includes(dataToSend.type)" sm="12" md="5" lg="5" xl="5" class="pt-4">
				<b-form-checkbox
					v-model="dataToSend.boolValue"
					switch
					size="lg"
					:value="true"
					:unchecked-value="false"
					:disabled="disableAll"
					@change="emitChange"
				>
					<div class="d-flex align-items-center fs-14">{{ FormMSG(19, 'Re-use valuation') }}</div>
				</b-form-checkbox>
			</b-col>-->
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';

export default {
	name: 'CoTwoHmcForm',

	mixins: [languageMessages],

	props: {
		coTwoData: { type: Object, required: true },
		forCarbonTemplate: { type: Boolean, default: false, required: false },
		disableAll: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			hmcReasonSelected: 0,
			dataToSend: {
				type: 0,
				boolValue: false
			}
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.type = newVal.type;
					this.dataToSend.boolValue = newVal.boolValue;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		hmcOptions() {
			return this.FormMenu(11008);
		}
	},

	methods: {
		handleChangeType() {
			this.emitChange();
		},
		emitChange() {
			this.$emit('co-two-hmc:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
