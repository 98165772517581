<template>
	<div>
		<b-row>
			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(10, 'Vehicle type')" class="mb-0">
					<b-form-select
						v-model="dataToSend.type"
						:options="vehicleType"
						:placeholder="FormMSG(11, 'Please select')"
						:disabled="disableAll"
						@change="handleChangeType"
					/>
				</b-form-group>
			</b-col>

			<b-col v-if="!isKm" sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(12, 'Fuel consumption')">
					<b-input-group>
						<b-form-input 
							v-model="dataToSend.fuelConsumption" 
							:isDisable="false"
							:addUnit="true"
							:enableExpend="false"
							:is-for-number="false"
							@change="emitChange"
						/>
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(14, 'L') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>

			<b-col v-else sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(13, 'Distance')">
					<b-input-group>
						<b-form-input 
							v-model="dataToSend.km" 
							:isDisable="false"
							:addUnit="true"
							:enableExpend="false"
							:is-for-number="false"
							@change="emitChange"
						/>
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(15, 'Km') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import CustomFormGroup from '@/components/Forms/CustomFormGroup';
import { validationMixin } from 'vuelidate';
import { decimal } from 'vuelidate/lib/validators';
import _ from 'lodash';

export default {
	name: 'CoTwoFilmVehicleForm',

	mixins: [languageMessages, validationMixin],
	components: {
		CustomFormGroup
	},

	props: {
		coTwoData: { type: Object, required: true },
		forCarbonTemplate: { type: Boolean, default: false, required: false },
		disableAll: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			dataToSend: {
				type: 0,
				km: 0,
				fuelConsumption: 0
			}
		};
	},

	validations: {
		dataToSend: {
			km: { decimal: decimal },
			fuelConsumption: { decimal: decimal }
		}
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) && !_.isNil(newVal)) {
					this.dataToSend.type = newVal.type;
					this.dataToSend.km = !_.isNil(newVal.km) ? parseFloat(newVal.km).toFixed(2) : 0;
					this.dataToSend.fuelConsumption = !_.isNil(newVal.fuelConsumption) ? parseFloat(newVal.fuelConsumption).toFixed(2) : 0;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		vehicleType() {
			return this.FormMenu(1447);
		},
		isKm() {
			return [1, 2, 3, 4].includes(this.dataToSend.type);
		}
	},

	methods: {
		handleChangeType(payload) {
			this.emitChange();
		},
		handleInputChange() {
			this.dataToSend.km = parseFloat(this.dataToSend.km).toFixed(2);
			this.dataToSend.fuelConsumption = parseFloat(this.dataToSend.fuelConsumption).toFixed(2);
		},
		emitChange() {
			this.handleInputChange();
			this.$emit('co-two-film-vehicle:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
