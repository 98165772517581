<template>
	<div>
		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(49, 'Activity type')">
					<b-form-select
						v-model="$v.dataToSend.type.$model"
						:options="activityTypeOptions"
						:state="getStateValidationField('dataToSend.type')"
						@change="emitChange"
					/>
					<div v-if="$v.dataToSend.type.$error && getStateValidationField('dataToSend.type') !== null" class="invalid-feedback">
						{{ FormMSG(251, 'Please select activity type') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(50, 'Activity country')">
					<b-form-select
						v-model="$v.dataToSend.subType.$model"
						:options="optionsForCountries"
						:state="getStateValidationField('dataToSend.subType')"
						@change="emitChange"
					/>
					<div v-if="$v.dataToSend.subType.$error && getStateValidationField('dataToSend.subType') !== null" class="invalid-feedback">
						{{ FormMSG(252, 'Please select activity country') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(51, 'Reporting type')">
					<b-form-select
						v-model="$v.dataToSend.additionalSubType.$model"
						:options="reportingTypeOptions"
						:state="getStateValidationField('dataToSend.additionalSubType')"
						@change="emitChange"
					/>
					<div
						v-if="$v.dataToSend.additionalSubType.$error && getStateValidationField('dataToSend.additionalSubType') !== null"
						class="invalid-feedback"
					>
						{{ FormMSG(253, 'Please select reporting type') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
		<b-collapse :visible="dataToSend.additionalSubType === 2 && !forCarbonTemplate">
			<div v-if="dataToSend.additionalSubType === 2 && !forCarbonTemplate">
				<b-row>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(52, 'Post-production means')">
							<b-form-select
								v-model="$v.dataToSend.postProductionMeansType.$model"
								:options="postProductionMeansOptions"
								:state="getStateValidationField('dataToSend.postProductionMeansType')"
								@change="emitChange"
							/>
							<div
								v-if="$v.dataToSend.postProductionMeansType.$error && getStateValidationField('dataToSend.postProductionMeansType') !== null"
								class="invalid-feedback"
							>
								{{ FormMSG(296, 'Please select post-production means type') }}
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(53, 'Number of working days')">
							<b-input-group>
								<b-form-input
									v-model="$v.dataToSend.numberOfWorkingDays.$model"
									:state="getStateValidationField('dataToSend.numberOfWorkingDays')"
									@change="emitChange"
								/>
								<b-input-group-append>
									<b-input-group-text class="bg-color-north-wind text-color-white">
										{{ FormMSG(54, 'man-day') }}
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<div
								v-if="$v.dataToSend.numberOfWorkingDays.$error && getStateValidationField('dataToSend.numberOfWorkingDays') !== null"
								class="invalid-feedback"
							>
								{{ FormMSG(265, 'Please enter number of working days') }}
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(55, 'Number of extra-staff days')">
							<b-input-group>
								<b-form-input
									v-model="$v.dataToSend.numberOfExtraStaffDays.$model"
									:state="getStateValidationField('dataToSend.numberOfExtraStaffDays')"
									@change="emitChange"
								/>
								<b-input-group-append>
									<b-input-group-text class="bg-color-north-wind text-color-white">
										{{ FormMSG(54, 'man-day') }}
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<div
								v-if="$v.dataToSend.numberOfExtraStaffDays.$error && getStateValidationField('dataToSend.numberOfExtraStaffDays') !== null"
								class="invalid-feedback"
							>
								{{ FormMSG(269, 'Please enter number of extra-staff days') }}
							</div>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</b-collapse>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { noTilde } from '@/shared/utils';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { ACTIVITY_TYPE_MENU, POST_PRODUCTION_MEANS_MENU, REPORTING_TYPE_MENU } from '../../../../../shared/constants';

export default {
	name: 'CoTwoPostProductionActivitiesForm',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		coTwoData: { type: Object, default: () => ({}), required: false },
		disableAll: { type: Boolean, default: false, required: false },
		forCarbonTemplate: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			dataToSend: {
				type: 0,
				subType: 0,
				additionalSubType: 0,
				postProductionMeansType: 0,
				numberOfWorkingDays: 0,
				numberOfExtraStaffDays: 0
			}
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.type = newVal.type;
					this.dataToSend.subType = newVal.subType;
					this.dataToSend.additionalSubType = newVal.additionalSubType;
					this.dataToSend.postProductionMeansType = newVal.postProductionMeansType;
					this.dataToSend.numberOfWorkingDays = parseFloat(newVal.numberOfWorkingDays);
					this.dataToSend.numberOfExtraStaffDays = parseFloat(newVal.numberOfExtraStaffDays);
				}
			},
			immediate: true,
			deep: true
		}
	},

	validations() {
		return {
			dataToSend: {
				type: { minValue: minValue(1) },
				subType: { minValue: minValue(1) },
				additionalSubType: { minValue: minValue(1) },
				postProductionMeansType: {
					minValue: this.dataToSend.additionalSubType === 2 ? minValue(1) : {}
				},
				numberOfWorkingDays: {
					required: this.dataToSend.additionalSubType === 2 ? required : {},
					minValue: this.dataToSend.additionalSubType === 2 ? minValue(1) : {}
				},
				numberOfExtraStaffDays: {
					required: this.dataToSend.additionalSubType === 2 ? required : {},
					minValue: this.dataToSend.additionalSubType === 2 ? minValue(0) : {}
				}
			}
		};
	},

	computed: {
		optionsForCountries() {
			let options = this.FormMenu(8);
			options.sort((a, b) => {
				if (noTilde(a.text) < noTilde(b.text)) {
					return -1;
				}
				if (noTilde(a.text) > noTilde(b.text)) {
					return 1;
				}

				return 0;
			});

			options = [
				{
					value: 0,
					text: this.FormMSG(1, 'Select a country ...')
				},
				...options
			];

			return options;
		},
		activityTypeOptions() {
			return [{ text: this.FormMSG(152, 'Please select ...'), value: 0 }, ...this.FormMenu(ACTIVITY_TYPE_MENU)];
		},
		reportingTypeOptions() {
			return [{ text: this.FormMSG(152, 'Please select ...'), value: 0 }, ...this.FormMenu(REPORTING_TYPE_MENU)];
		},
		postProductionMeansOptions() {
			return [{ text: this.FormMSG(152, 'Please select ...'), value: 0 }, ...this.FormMenu(POST_PRODUCTION_MEANS_MENU)];
		}
	},

	methods: {
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		emitChange() {
			this.dataToSend.numberOfWorkingDays = parseFloat(this.dataToSend.numberOfWorkingDays);
			this.dataToSend.numberOfExtraStaffDays = parseFloat(this.dataToSend.numberOfExtraStaffDays);

			this.$emit('co-two-post-production-activities-form:change', this.dataToSend);
		}
	}
};
</script>
