<template>
	<div>
		<b-row>
			<b-col sm="6" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(15, 'Fuel type')">
					<b-form-select
						v-model="dataToSend.type"
						:options="fuelTypeOptions"
						:placeholder="FormMSG(52, 'Please select')"
						:disabled="disableAll"
						@change="handleChangeFuelType"
					/>
				</b-form-group>
			</b-col>
			<b-col v-if="!hideQuantity" sm="6" md="3" lg="3" xl="3">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(16, 'Quantity')">
					<b-input-group>
						<b-form-input
							v-model="dataToSend.liter"
							:disabled="disableAll"
							:class="{ 'bg-color-grams-hair': disableAll }"
							@change="handleChangeQuantity"
						/>
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(17, 'L') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-90 mr-3">
						<b-form-group :label="FormMSG(16, 'Quantity')">
							<b-form-input
								v-model="dataToSend.liter"
								:disabled="disableAll"
								:class="{ 'bg-color-grams-hair': disableAll }"
								@change="handleChangeQuantity"
							/>
						</b-form-group>
					</div>
					<div class="d-flex align-items-center pt-3 text-capitalize">
						<div class="fs-20 fw-700">{{ FormMSG(17, 'L') }}</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';

export default {
	name: 'CoTwoFuelForm',

	mixins: [languageMessages],

	props: {
		coTwoData: { type: Object, required: true },
		hideQuantity: { type: Boolean, default: false, required: false },
		disableAll: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			dataToSend: {
				type: 0,
				mcube: 0,
				liter: 0
			}
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) && !_.isNil(newVal)) {
					this.dataToSend.type = newVal.type;
					this.dataToSend.mcube = newVal.mcube;
					this.dataToSend.liter = newVal.liter;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		fuelTypeOptions() {
			return this.FormMenu(11012);
		}
	},

	methods: {
		handleChangeQuantity(payload) {
			this.emitChange();
		},
		handleChangeFuelType(payload) {
			this.emitChange();
		},
		emitChange() {
			this.dataToSend.mcube = parseFloat(this.dataToSend.mcube);
			this.dataToSend.liter = parseFloat(this.dataToSend.liter);

			this.$emit('co-two-fuel:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
