<template>
	<div>
		<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside bg-color-white border-ligth-starlight' : ''}`">
			<legend v-if="$screen.width <= 576" :class="`${$screen.width <= 576 ? 'card-inside' : ''}`" class="text-color-rhapsody-in-blue-2">
				{{ FormMSG(697, 'Water') }}
			</legend>
			<div :class="{ 'pt-1 pb-1': $screen.width <= 576 }">
				<b-row>
					<b-col v-if="$screen.width > 576" sm="12" md="2" lg="2" xl="2">
						<b-form-input :value="FormMSG(697, 'Water')" class="bg-color-grams-hair" disabled />
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<supplier-energy-consumption
							:label="FormMSG(633, 'Supplier')"
							:dialog-title="FormMSG(253, 'Water supplier')"
							:hide-label="$screen.width > 576"
							supplier-type="water"
							:label-add-supplier="FormMSG(198, 'New water supplier')"
							:supplier-id="payload.waterSupplier"
							:disabled="disabled"
							@supplier-energy:change="handleSupplierSelectorChange"
							@supplier-energy:unselect="handleSupplierUnselect"
						/>
					</b-col>
					<b-col sm="12" md="2" lg="2" xl="2">
						<b-form-group :label="$screen.width <= 576 ? FormMSG(58, 'Meter start') : ''">
							<b-input-group>
								<b-form-input
									v-model="payload.waterMeterStart"
									class="br-none"
									@input="computeWaterConsumption"
									:disabled="disabled"
									:class="{ 'bg-color-grams-hair': disabled }"
								/>
								<b-input-group-append>
									<b-input-group-text class="bg-color-white input-group-text-bg-white">
										<div class="d-flex">
											<button
												v-if="has(payload.waterMeterStartPict) && payload.waterMeterStartPict !== ''"
												class="btn-transparent text-color-blue-rinse"
												:disabled="disabled"
												@click="showPicture([payload.waterMeterStartPict])"
											>
												<component :is="getLucideIcon('Image')" :size="20" />
											</button>
											<button
												v-if="!$isPwa()"
												class="btn-transparent text-color-rhapsody-in-blue"
												:disabled="disabled"
												@click="openWaterPicModalStart"
											>
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
											<button
												v-if="$isPwa()"
												class="btn-transparent text-color-rhapsody-in-blue"
												:disabled="disabled"
												@click="startMobilePwaCapture('handleWaterPicStart')"
											>
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
										</div>
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="2" lg="2" xl="2">
						<b-form-group :label="$screen.width <= 576 ? FormMSG(59, 'Meter end') : ''">
							<b-input-group>
								<b-form-input
									v-model="payload.waterMeterEnd"
									@input="computeWaterConsumption"
									class="br-none"
									:disabled="disabled"
									:class="{ 'bg-color-grams-hair': disabled }"
								/>
								<b-input-group-append>
									<b-input-group-text class="bg-color-white input-group-text-bg-white">
										<div class="d-flex">
											<button
												v-if="has(payload.waterMeterEndPict) && payload.waterMeterEndPict !== ''"
												class="btn-transparent text-color-blue-rinse"
												:disabled="disabled"
												@click="showPicture([payload.waterMeterEndPict])"
											>
												<component :is="getLucideIcon('Image')" :size="20" />
											</button>
											<button
												v-if="!$isPwa()"
												class="btn-transparent text-color-rhapsody-in-blue"
												:disabled="disabled"
												@click="openWaterPicModalEnd"
											>
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
											<button
												v-if="$isPwa()"
												class="btn-transparent text-color-rhapsody-in-blue"
												:disabled="disabled"
												@click="startMobilePwaCapture('handleWaterPicEnd')"
											>
												<component :is="getLucideIcon('Paperclip')" :size="20" />
											</button>
										</div>
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
						<small v-if="isInvalid" style="color: red">{{ FormMSG(1101, 'Meter end must be greater than meter start') }} </small>
					</b-col>
					<b-col sm="12" md="2" lg="2" xl="2">
						<b-form-group>
							<b-input-group>
								<b-form-input
									v-model="payload.consumptionWater"
									@input="computeWaterEnd"
									:disabled="disabled"
									:class="{ 'bg-color-grams-hair': disabled }"
								/>
								<b-input-group-append>
									<b-input-group-text class="bg-color-north-wind text-color-white">
										{{ FormMSG(260, 'm3') }}
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</fieldset>

		<Capture
			v-model="isWaterPicStartModalOpen"
			:custom-ref="rendRef('pic_elec_system_start')"
			auto-crop-capture
			only-one-crop-mode
			:parent-id="parentId"
			parent-type="location"
			parent-sub-type="location_water_start"
			@change="handleWaterPicStart"
		/>
		<Capture
			v-model="isWaterPicEndModalOpen"
			:custom-ref="rendRef('pic_elec_system_end')"
			auto-crop-capture
			only-one-crop-mode
			:parent-id="parentId"
			parent-type="location"
			parent-sub-type="location_water_end"
			@change="handleWaterPicEnd"
		/>
	</div>
</template>

<script>
import meterHelperMixin from './meterHelperMixin';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import SupplierSelector from '@/components/SupplierSelector';
import SupplierEnergyConsumption from './SupplierEnergyConsumption';

export default {
	name: 'LocationServiceWaterIncludeComponent',
	components: {
		SupplierEnergyConsumption,
		Capture: () => import('@/components/CaptureModule/Main'),
		SupplierSelector
	},
	mixins: [languageMessages, meterHelperMixin, globalMixin],
	data() {
		return {
			isWaterPicStartModalOpen: false,
			isWaterPicEndModalOpen: false,
			payload: {
				waterMeterEnd: 0,
				waterMeterEndPict: '',
				waterMeterStart: 0,
				waterMeterStartPict: '',
				waterSupplier: 0,
				consumptionWater: 0
			},
			isInvalid: false,
			consumption: 0
		};
	},
	watch: {
		editData: {
			handler(newVal) {
				this.payload = {
					waterMeterEnd: newVal.waterMeterEnd,
					waterMeterEndPict: newVal.waterMeterEndPict,
					waterMeterStart: newVal.waterMeterStart,
					waterMeterStartPict: newVal.waterMeterStartPict,
					waterSupplier: newVal.waterSupplier,
					consumptionWater: newVal.consumptionWater
				};
			},
			immediate: true
		},
		payload: {
			handler(e) {
				this.$emit('change', {
					...e,
					waterMeterEnd: parseFloat(e.waterMeterEnd),
					waterMeterStart: parseFloat(e.waterMeterStart),
					consumptionWater: parseFloat(e.consumptionWater)
				});
			},
			deep: true
		}
		// 'payload.waterMeterEnd': {
		// 	handler(newVal) {
		// 		this.payload.consumptionWater = parseFloat(newVal) - parseFloat(this.payload.waterMeterStart);
		// 	},
		// 	immediate: true
		// }
	},
	computed: {
		waterConsumption: {
			get() {
				let start = parseFloat(this.payload.waterMeterStart) || 0;
				let end = parseFloat(this.payload.waterMeterEnd) || 0;
				let consumption = end - start;
				consumption < 0 ? (this.isInvalid = true) : (this.isInvalid = false);
				this.payload.consumptionWater = consumption;
				return consumption;
			},
			set(newVal) {
				this.payload.waterMeterStart = 0;
				this.payload.waterMeterEnd = newVal;
			}
		}
	},
	methods: {
		computeWaterEnd() {
			let start = parseFloat(this.payload.waterMeterStart) || 0;
			let consumption = parseFloat(this.payload.consumptionWater) || 0;
			let end = consumption + start;
			this.payload.waterMeterEnd = end;
		},
		computeWaterConsumption() {
			let start = parseFloat(this.payload.waterMeterStart) || 0;
			let end = parseFloat(this.payload.waterMeterEnd) || 0;
			let consumption = end - start;
			consumption < 0 ? (this.isInvalid = true) : (this.isInvalid = false);
			this.payload.consumptionWater = consumption;
		},
		handleSupplierUnselect() {
			this.payload.waterSupplier = 0;
		},
		handleSupplierSelectorChange(payload) {
			this.payload.waterSupplier = parseInt(payload.code, 10);
		},
		openWaterPicModalStart() {
			this.isWaterPicStartModalOpen = true;
		},
		openWaterPicModalEnd() {
			this.isWaterPicEndModalOpen = true;
		},

		handleWaterPicStart(imgs) {
			this.payload.waterMeterStartPict = imgs[0];
			this.save('waterMeterStartPict', imgs[0]);
		},
		handleWaterPicEnd(imgs) {
			this.payload.waterMeterEndPict = imgs[0];
			this.save('waterMeterEndPict', imgs[0]);
		}
	}
};
</script>
