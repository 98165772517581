var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "co-two-clap-container" } },
    [
      !_vm.hideCoTwoCategorySelector
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-1",
                      attrs: { label: _vm.FormMSG(14, "CO2 type") },
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.coTwoTypeOptions,
                          placeholder: _vm.FormMSG(85, "Select CO2 type"),
                          disabled:
                            _vm.disableCarbonTypeSelector || _vm.disableAll,
                        },
                        on: { change: _vm.handleChangeCoTwoCategory },
                        model: {
                          value: _vm.dataToSend.coTwoCategory,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "coTwoCategory", $$v)
                          },
                          expression: "dataToSend.coTwoCategory",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.forCarbonTemplate
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "text-color-blue-streak d-flex align-items-center",
                        },
                        [
                          _c(_vm.getLucideIcon("Info"), {
                            tag: "component",
                            attrs: { size: 16 },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "ml-1",
                              staticStyle: { "margin-top": "3px" },
                            },
                            [_vm._v(_vm._s(_vm.infoText))]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataToSend.coTwoCategory === 19
                    ? _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(15, "Type") } },
                        [
                          _c("b-form-select", {
                            attrs: {
                              options: _vm.co2TypeInsuranceAndService,
                              placeholder: _vm.FormMSG(
                                459,
                                "Please select here ..."
                              ),
                            },
                            on: { input: _vm.handleTypeChange },
                            model: {
                              value: _vm.dataToSend.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToSend, "type", $$v)
                              },
                              expression: "dataToSend.type",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.dataToSend.coTwoCategory === 1 ||
              _vm.dataToSend.coTwoCategory === 15
                ? _c(
                    "b-col",
                    [
                      _c(
                        "b-row",
                        {
                          staticStyle: { "align-items": "center" },
                          attrs: { sm: "12", md: "4", lg: "4", xl: "4" },
                        },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.locationPremiseSelectorLabel,
                                  },
                                },
                                [
                                  _c("v-select", {
                                    class: {
                                      "is-invalid":
                                        _vm.$v.dataToSend.locationId.$error,
                                    },
                                    attrs: {
                                      options: _vm.locationOptions,
                                      reduce: (option) => +option.id,
                                      label: "setName",
                                      placeholder: _vm.locationPlaceholder,
                                      clearable: false,
                                      disabled:
                                        !_vm.forCreate &&
                                        _vm.hideLocationSelector,
                                    },
                                    on: { input: _vm.handleInputLocationId },
                                    model: {
                                      value:
                                        _vm.$v.dataToSend.locationId.$model,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.$v.dataToSend.locationId,
                                          "$model",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "$v.dataToSend.locationId.$model",
                                    },
                                  }),
                                  _vm.$v.dataToSend.locationId.$error
                                    ? _c("b-form-invalid-feedback", [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              _vm.dataToSend.coTwoCategory === 1
                                                ? _vm.FormMSG(
                                                    1546,
                                                    "Location is required"
                                                  )
                                                : _vm.FormMSG(
                                                    1547,
                                                    "Premise is required"
                                                  )
                                            ) +
                                            "\n\t\t\t\t\t\t"
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { id: "ancillarySpaceConsumption" },
                                  model: {
                                    value:
                                      _vm.dataToSend.ancillarySpaceConsumption,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dataToSend,
                                        "ancillarySpaceConsumption",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dataToSend.ancillarySpaceConsumption",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(
                                        16,
                                        "Ancillary space consumption"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showMeasurementSelector
                ? _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(875, "Measurement") } },
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.measurementOptions },
                            on: { change: _vm.emitChange },
                            model: {
                              value: _vm.dataToSend.typeMeasurement,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToSend, "typeMeasurement", $$v)
                              },
                              expression: "dataToSend.typeMeasurement",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showDistanceKnown
                ? _c(
                    "b-col",
                    {
                      staticClass: "pt-4",
                      attrs: { sm: "12", md: "4", lg: "4", xl: "4" },
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            size: "lg",
                            disabled: _vm.disableAll,
                            switch: "",
                          },
                          on: { change: _vm.handleChangeDistanceKnown },
                          model: {
                            value: _vm.transportDistanceKnown,
                            callback: function ($$v) {
                              _vm.transportDistanceKnown = $$v
                            },
                            expression: "transportDistanceKnown",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex align-items-center fs-14",
                              staticStyle: { "margin-top": "2px" },
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(247, "Distance known")))]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dataToSend.coTwoCategory === 3
                ? _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(1987, "Generator") } },
                        [
                          _c("v-select", {
                            class: {
                              "is-invalid":
                                _vm.$v.dataToSend.specialEquipmentId.$error,
                            },
                            attrs: {
                              options: _vm.listGenerators,
                              reduce: (option) => +option.id,
                              label: "name",
                              placeholder: _vm.FormMSG(
                                459,
                                "Please select here ..."
                              ),
                              clearable: false,
                            },
                            on: { input: _vm.handleInputLocationId },
                            model: {
                              value:
                                _vm.$v.dataToSend.specialEquipmentId.$model,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.$v.dataToSend.specialEquipmentId,
                                  "$model",
                                  $$v
                                )
                              },
                              expression:
                                "$v.dataToSend.specialEquipmentId.$model",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 1 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 1 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-location-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-location:change": _vm.handleCoTwoLocationChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 2 } },
        [
          _vm.dataToSend.coTwoCategory === 2
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-accomodation-form", {
                    ref: "coTwoAccommodationFormRef",
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "hide-number-of-bedroom": _vm.forCarbonTemplate,
                      "hide-number-of-night": _vm.forCarbonTemplate,
                      "hide-total-number-of-night": _vm.forCarbonTemplate,
                      "disable-forms": _vm.disableAccomodationForms,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-accomodation:change":
                        _vm.handleCoTwoAccomodationChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 3 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 3 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-energy-form", {
                    ref: "coTwoEnergyFormRef",
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "disable-all": _vm.disableAll,
                    },
                    on: { "co-two-energy:change": _vm.handleCoTwoEnergyChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 4 } },
        [
          _vm.dataToSend.coTwoCategory === 4
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-transport-form", {
                    ref: "coTwoTransportFormRef",
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "is-distance-known": _vm.transportDistanceKnown,
                      "type-measurement": _vm.dataToSend.typeMeasurement,
                      "hide-from-address":
                        _vm.forCarbonTemplate || _vm.hideFromAddressTransport,
                      "hide-to-address":
                        _vm.forCarbonTemplate || _vm.hideToAddressTransport,
                      "disable-forms": _vm.disableTransportForms,
                      "use-address-string": _vm.useAddressTransportString,
                      "hide-distance-known": _vm.hideDistanceKnownTransport,
                      "hide-transport-type-selector":
                        _vm.hideTransportTypeSelectorTransport,
                      "hide-distance": _vm.hideDistanceTransport,
                      "get-my-vehicules": _vm.getMyVehiculeInTransport,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-transport:change": _vm.handleCoTwoTransportChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 6 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 6 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-meal-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "disable-all": _vm.disableAll,
                    },
                    on: { "co-two-meal:change": _vm.handleCoTwoMealChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 7 } },
        [
          _vm.dataToSend.coTwoCategory === 7
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-hmc-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "for-carbon-template": _vm.forCarbonTemplate,
                      "disable-all": _vm.disableAll,
                    },
                    on: { "co-two-hmc:change": _vm.handleCoTwoHmcChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 8 } },
        [
          _vm.dataToSend.coTwoCategory === 8
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-set-creation-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "for-carbon-template": _vm.forCarbonTemplate,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-set-creation:change":
                        _vm.handleCoTwoSetCreationChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 9 } },
        [
          _vm.dataToSend.coTwoCategory === 9
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-tehnical-production-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "for-carbon-template": _vm.forCarbonTemplate,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-tehnical-production:change":
                        _vm.handleCoTwoTechnicalProductionChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 10 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 10 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-transportation-shooting-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-transportation-shooting:change":
                        _vm.handleCoTwoTransportationShootingChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 5 } },
        [
          _vm.dataToSend.coTwoCategory === 5
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-waste-form", {
                    ref: "coTwoWasteFormRef",
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "hide-fields": _vm.forCarbonTemplate,
                      "disable-all": _vm.disableAll,
                      "disable-forms": _vm.disableWasteForms,
                    },
                    on: { "co-two-waste:change": _vm.handleCoTwoWasteChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 11 } },
        [
          _vm.dataToSend.coTwoCategory === 11
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-generator-energy-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "for-carbon-template": _vm.forCarbonTemplate,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-generator-energy:change":
                        _vm.handleCoTwoGeneratorEnergyChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 12 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 12 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-transport-car-lodges-form", {
                    ref: "coTwoTransportCarLodgesRef",
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-transport-car-lodges:change":
                        _vm.handleCoTwoTransportCarLodgesChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 13 } },
        [
          _vm.dataToSend.coTwoCategory === 13
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-fuel-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "hide-quantity": _vm.forCarbonTemplate,
                      "disable-all": _vm.disableAll,
                    },
                    on: { "co-two-fuel:change": _vm.handleCoTwoFuelChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 14 } },
        [
          _vm.dataToSend.coTwoCategory === 14
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-production-logistic", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-production-logistic:change":
                        _vm.handleCoTwoProductionLogistic,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 15 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 15 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-premise-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-premise:change": _vm.handleCoTwoPremiseChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 17 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 17 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                [
                  _c("co-two-group-transportation-form", {
                    ref: "coTwoGroupTransportRef",
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-group-transportation-form:change":
                        _vm.handleCoTwoGroupTransportationFormChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 18 } },
        [
          _vm.dataToSend.coTwoCategory === 18
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-film-vehicle-form", {
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "for-carbon-template": _vm.forCarbonTemplate,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-film-vehicle:change":
                        _vm.handleCoTwoFilmVehicleChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.coTwoCategory === 20 } },
        [
          _vm.dataToSend.coTwoCategory === 20
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-post-production-activities-form", {
                    ref: "coTwoPostProductionActivitiesRef",
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "for-carbon-template": _vm.forCarbonTemplate,
                      "disable-all": _vm.disableAll,
                    },
                    on: {
                      "co-two-post-production-activities-form:change":
                        _vm.handleCoTwoPostProductionActivitiesFormChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-collapse",
        {
          attrs: {
            visible:
              _vm.dataToSend.coTwoCategory === 21 && !_vm.forCarbonTemplate,
          },
        },
        [
          _vm.dataToSend.coTwoCategory === 21 && !_vm.forCarbonTemplate
            ? _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c("co-two-production-control-room-form", {
                    ref: "coTwoProductionControlRoomRef",
                    attrs: {
                      "co-two-data": _vm.carbonData,
                      "disable-all": _vm.disableAll,
                      "for-carbon-template": _vm.forCarbonTemplate,
                    },
                    on: {
                      "co-two-production-control-room:change":
                        _vm.handleCoTwoProductionControlRoomChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm.hideBtnReviewed === false
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-row",
                    [
                      _vm.parentId > 0 && _vm.dataToSend.verified === 3
                        ? _c(
                            "b-col",
                            {
                              staticClass: "mt-3",
                              attrs: {
                                sm: "12",
                                md: "5",
                                "offset-md": "7",
                                lg: "5",
                                "offset-lg": "7",
                                xl: "5",
                                "offset-xl": "7",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "text-primary fs-18 float-right",
                                },
                                [
                                  _c(_vm.getLucideIcon("Check"), {
                                    tag: "component",
                                    attrs: { size: 20 },
                                  }),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.FormMSG(812, "Already reviewed")
                                      ) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }