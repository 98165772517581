<template>
	<div>
		<b-collapse :visible="!canCompute">
			<b-row v-if="canCompute === false">
				<b-col sm="12">
					<b-alert show variant="warning">
						<component :is="getLucideIcon('AlertTriangle')" :size="16" /> {{ FormMSG(89, 'This transport mean type is not computed by Google') }}
					</b-alert>
				</b-col>
			</b-row>
		</b-collapse>

		<b-row>
			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(1954, 'Production phase')">
					<b-form-select
						v-model="dataToSend.productionPhase"
						:options="productionPhaseOptions"
						:disabled="disableForms || disableAll"
						:class="{ 'bg-color-grams-hair': disableAll }"
						@change="handleChangeProductionPhase"
					/>
				</b-form-group>
			</b-col>
			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(45, 'Vehicle')">
					<v-select
						v-model="dataToSend.vehiculeId"
						label="name"
						:options="vehicules"
						:placeholder="FormMSG(85, 'Please select')"
						:reduce="(option) => +option.id"
						:clearable="false"
						:disabled="disableForms || disableAll"
						@input="handleInputVehiculeId"
					/>
				</b-form-group>
			</b-col>

			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(17, 'Number of vehicle')">
					<b-form-input
						v-model="dataToSend.numberOfVehicule"
						:class="{ 'bg-color-grams-hair': disableForms }"
						:disabled="disableForms || disableAll"
						@change="handleChangeNumberVehicle"
					/>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row v-if="hideTransportTypeSelector === false">
			<b-col sm="12" :md="12" :lg="12" :xl="12">
				<transport-type-selector
					ref="transportTypSelectorCoTwo"
					:data-additional-detail="dataToSend.additionalSubType"
					:data-detail="dataToSend.subType"
					:data-type="dataToSend.type"
					:data-special-equipment="dataToSend.specialEquipmentId"
					:disable-form="disableForms || disableAll"
					@transportation-type-selector:change="handleTransportationTypeSelectorChange"
				/>
			</b-col>
		</b-row>

		<b-row v-if="$screen.width <= 576 && hideDistanceKnown === false" class="mt-2 mb-3">
			<b-col sm="12">
				<b-form-checkbox v-model="dataToSend.distanceKnown" size="lg" :disabled="disableForms || disableAll" switch>
					<div class="d-flex align-items-center fs-14">{{ FormMSG(247, 'Distance known') }}</div>
				</b-form-checkbox>
			</b-col>
		</b-row>
		<b-row>
			<b-col v-if="conditionForShowFromAddress" sm="12" md="4" lg="4" xl="4">
				<b-form-group v-if="useAddressString === false" :label="FormMSG(18, 'From')">
					<v-address
						:params="{ title: FormMSG(48, 'From address transport') }"
						:address-id="dataToSend.fromAddressId"
						:disabled="disableForms || disableAll"
						@change="handleChangeFromAddress"
						@delete="handleDeleteFromAddress"
					/>
				</b-form-group>
				<b-form-group v-else :label="FormMSG(18, 'From')">
					<b-form-input :value="coTwoData.fromAddress" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>
			<b-col v-if="conditionForShowToAddress" sm="12" md="4" lg="4" xl="4">
				<b-form-group v-if="useAddressString === false" :label="FormMSG(19, 'To')">
					<v-address
						:params="{ title: FormMSG(58, 'To address transport') }"
						:address-id="dataToSend.toAddressId"
						:disabled="disableForms || disableAll"
						@change="handleChangeToAddress"
						@delete="handleDeleteToAddress"
					/>
				</b-form-group>
				<b-form-group v-else :label="FormMSG(19, 'To')">
					<b-form-input :value="coTwoData.toAddress" class="bg-color-grams-hair" disabled />
				</b-form-group>
			</b-col>

			<b-col v-if="hideDistance === false" sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group v-if="$screen.width > 576 && typeMeasurement === 1" :label="FormMSG(20, 'Distance')">
					<b-input-group>
						<b-form-input
							v-model="dataToSend.km"
							:class="{ 'bg-color-grams-hair': !dataToSend.distanceKnown }"
							:disabled="!dataToSend.distanceKnown || disableForms || disableAll"
							@change="handleChangeDistance"
						/>
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ distanceUnit }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<b-form-group v-if="$screen.width > 576 && typeMeasurement === 2">
					<template #label>
						<div class="d-flex">
							<div class="mr-1">
								{{ FormMSG(1450, 'Fuel consumption') }}
							</div>
							<!--							<div-->
							<!--                v-if="false"-->
							<!--								class="text-color-tree-sap fw-600 cursor-pointer"-->
							<!--								v-b-popover.hover.top="FormMSG(1993, 'The fuel consumption is not included for the Ferry transport type')"-->
							<!--								:title="FormMSG(1983, 'Info')"-->
							<!--								style="margin-top: -2px"-->
							<!--							>-->
							<!--								<component :is="getLucideIcon('AlertTriangle')" :size="16" />-->
							<!--							</div>-->
						</div>
					</template>
					<b-input-group>
						<b-form-input v-model="dataToSend.fuelConsumption" :disabled="disableForms || disableAll" @change="handleChangeFuelConsumption" />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(1451, 'L') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
				<b-form-group v-if="$screen.width > 576 && typeMeasurement === 3" :label="FormMSG(1489, 'Navigation duration')">
					<input-date-time-picker
						:label="FormMSG(18, 'Flight duration')"
						:uiid="'flightDuration'"
						:type="'text'"
						:placeholder="'hh:mm'"
						:item="dataToSend.navigationDuration"
						:error="null"
						:nbHour="rangeHour[WORKTYPE.daily]"
						:nbMinute="rangeMinute[WORKTYPE.daily]"
						:fieldName="'number'"
						:isEditable="() => !disableAll"
						:workType="WORKTYPE.daily"
						@change="handleChangeNavigationDuration"
					/>
				</b-form-group>
				<div v-if="$screen.width <= 576 && typeMeasurement === 1" class="d-flex mt-3">
					<div class="w-90 mr-3">
						<b-form-group :label="FormMSG(20, 'Distance')">
							<b-form-input
								v-model="dataToSend.km"
								:class="{ 'bg-color-grams-hair': !dataToSend.distanceKnown }"
								:disabled="!dataToSend.distanceKnown || disableForms || disableAll"
								@change="handleChangeDistance"
							/>
						</b-form-group>
					</div>
					<div class="d-flex align-items-center pt-3 text-capitalize">
						<div class="fs-20 fw-700">{{ distanceUnit }}</div>
					</div>
				</div>
				<div v-if="$screen.width <= 576 && typeMeasurement === 2" class="d-flex mt-3">
					<div class="w-90 mr-3">
						<b-form-group>
							<template #label>
								<div class="d-flex">
									<div class="mr-1">
										{{ FormMSG(1450, 'Fuel consumption') }}
									</div>
									<div
										v-if="dataToSend.type === 1 || dataToSend.type === 2"
										class="text-color-tree-sap fw-600 cursor-pointer"
										v-b-popover.hover.top="FormMSG(1993, 'The fuel consumption is not included for the Ferry transport type')"
										:title="FormMSG(1983, 'Info')"
										style="margin-top: -2px"
									>
										<component :is="getLucideIcon('AlertTriangle')" :size="16" />
									</div>
								</div>
							</template>
							<b-form-input
								v-model="dataToSend.fuelConsumption"
								:disabled="disableForms || disableAll || dataToSend.type === 1 || dataToSend.type === 2"
								@change="handleChangeFuelConsumption"
							/>
						</b-form-group>
					</div>
					<div class="d-flex align-items-center pt-3 text-capitalize">
						<div class="fs-20 fw-700">{{ FormMSG(1451, 'L') }}</div>
					</div>
				</div>
				<div v-if="$screen.width <= 576 && typeMeasurement === 3" class="d-flex mt-3">
					<div class="w-90 mr-3">
						<b-form-group :label="FormMSG(1489, 'Navigation duration')">
							<b-form-input
								v-model="dataToSend.navigationDuration"
								:disabled="disableForms || disableAll"
								@change="handleChangeFuelConsumption"
							/>
						</b-form-group>
					</div>
					<div class="d-flex align-items-center pt-3 text-capitalize">
						<div class="fs-20 fw-700">{{ FormMSG(1451, 'L') }}</div>
					</div>
				</div>
			</b-col>
			<b-col v-if="[9, 10, 11, 12, 19].includes(transportTypeMapped)" sm="12" md="4" lg="4" xl="4">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(21, 'Material weight (if Freight)')">
					<b-input-group>
						<b-form-input
							v-model="$v.dataToSend.number.$model"
							:disabled="disableForms || disableAll"
							:state="getStateValidationField('dataToSend.number')"
							@change="handleChangeMaterialWeight"
						/>
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(29, 'kg') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
					<div v-if="$v.dataToSend.number.$error && getStateValidationField('dataToSend.number') !== null" class="invalid-feedback">
						{{ FormMSG(874, 'Please enter material weight') }}
					</div>
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-90 mr-3">
						<b-form-group :label="FormMSG(21, 'Material weight (if Freight)')">
							<b-form-input
								v-model="$v.dataToSend.number.$model"
								:disabled="disableForms || disableAll"
								:state="getStateValidationField('dataToSend.number')"
								@change="handleChangeMaterialWeight"
							/>
							<div v-if="$v.dataToSend.number.$error && getStateValidationField('dataToSend.number') !== null" class="invalid-feedback">
								{{ FormMSG(874, 'Please enter material weight') }}
							</div>
						</b-form-group>
					</div>
					<div class="d-flex align-items-center pt-3 text-capitalize">
						<div class="fs-20 fw-700">{{ FormMSG(29, 'kg') }}</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { store } from '@/store';
import _ from 'lodash';
import { getVehicules, getVehicule } from '@/cruds/vehicules.crud';
import { getAddress } from '@/cruds/address.crud';
import { fetchDistance } from '~helpers';
import { getTravelModeFromTransportMean, setDurationToDate } from '@/modules/transports/utils';
import MainAddressLocation from '@/components/LocationService/MainAddressLocation';
import moment from 'moment';
import TransportTypeSelector from '@/modules/transports/components/TransportTypeSelector.vue';
import { useWorktype, useRangeHour, useRangeMinute } from '../../../../../utils/utils';
import InputDateTimePicker from '@/components/InputDateTimePicker/InputDateTimePicker';
import { PRODUCTION_PHASE_MENU } from '../../../../../shared/constants';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';

const EXCEPTION_TO_COMPUTE = [5, 7, 9, 18, 19, 20, 41, 42, 43];

export default {
	name: 'CoTwoTransportForm',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		isDistanceKnown: { type: Boolean, default: false, required: false },
		coTwoData: { type: Object, default: () => ({}), required: false },
		hideToAddress: { type: Boolean, default: false, required: false },
		hideFromAddress: { type: Boolean, default: false, required: false },
		disableForms: { type: Boolean, default: false, required: false },
		useAddressString: { type: Boolean, default: false, required: false },
		hideDistanceKnown: { type: Boolean, default: false, required: false },
		hideTransportTypeSelector: { type: Boolean, default: false, required: false },
		hideDistance: { type: Boolean, default: false, required: false },
		getMyVehicules: { type: Boolean, default: false, required: false },
		disableAll: { type: Boolean, default: false, required: false },
		typeMeasurement: { type: Number, default: 1, required: false }
	},

	components: {
		'v-address': MainAddressLocation,
		TransportTypeSelector,
		InputDateTimePicker
	},

	data() {
		return {
			vehicules: [],
			WORKTYPE: useWorktype,
			rangeHour: useRangeHour,
			rangeMinute: useRangeMinute,

			dataToSend: {
				isFreighter: false,
				vehiculeId: null,
				type: 0,
				subType: 0,
				additionalSubType: 0,
				reason: null,
				numberOfVehicule: 1,
				distanceKnown: false,
				materialWeight: 0,
				km: 0,
				fromAddressId: 0,
				toAddressId: 0,
				number: 0,
				navigationDuration: 0,
				fuelConsumption: 0,
				productionPhase: 0,
				specialEquipmentId: 0
			},

			transportTypeMapped: 0,
			vehiculeSelected: null,

			canCompute: true
		};
	},

	validations() {
		const CHECK = [9, 10, 11, 12, 19];
		return {
			dataToSend: {
				number: {
					required: CHECK.includes(this.transportTypeMapped) ? required : {},
					minValue: CHECK.includes(this.transportTypeMapped) ? minValue(1) : {}
				}
			}
		};
	},

	watch: {
		typeMeasurement: {
			handler(newVal) {
				if (newVal === 1) {
					this.dataToSend.fuelConsumption = 0;
					this.dataToSend.navigationDuration = 0;
				} else if (newVal === 2) {
					this.dataToSend.km = 0;
					this.dataToSend.navigationDuration = 0;
				} else if (newVal === 3) {
					this.dataToSend.km = 0;
					this.dataToSend.fuelConsumption = 0;
				}
			},
			immediate: true,
			deep: true
		},
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.vehiculeId = +newVal.vehiculeId === 0 ? null : +newVal.vehiculeId;
					this.dataToSend.type = +newVal.type;
					this.dataToSend.subType = +newVal.subType;
					this.dataToSend.additionalSubType = +newVal.additionalSubType;
					this.dataToSend.reason = +newVal.reason;
					this.dataToSend.numberOfVehicule = !_.isNaN(+newVal.numberOfVehicule) && +newVal.numberOfVehicule > 0 ? +newVal.numberOfVehicule : 1;
					// this.dataToSend.distanceKnown = newVal.boolValue;
					this.dataToSend.number = parseFloat(newVal.number);
					this.dataToSend.km = +newVal.km;
					this.dataToSend.fromAddressId = +newVal.fromAddressId;
					this.dataToSend.toAddressId = +newVal.toAddressId;
					// this.dataToSend.number = parseFloat(newVal.number);
					// this.vehiculeSelected = `${newVal.vehiculeId}`;
					this.dataToSend.productionPhase = +newVal.productionPhase;
					this.dataToSend.specialEquipmentId = +newVal.specialEquipmentId;

					this.transportTypeMapped = this.mappedTransportTypeToCarbonClapValue(
						this.dataToSend.type,
						this.dataToSend.subType,
						this.dataToSend.additionalSubType
					);

					this.dataToSend.fuelConsumption = parseFloat(newVal.fuelConsumption);
					this.$nextTick(() => {
						this.dataToSend.navigationDuration = this.convertMinutesToHours(newVal.navigationDuration);
					});
				}
			},
			immediate: true
		},
		isDistanceKnown: {
			handler(newVal) {
				this.dataToSend.distanceKnown = _.cloneDeep(newVal);
			},
			immediate: true,
			deep: true
		},
		transportTypeMapped: {
			handler(newVal) {
				if (EXCEPTION_TO_COMPUTE.includes(newVal) === false) {
					this.canCompute = true;
				} else {
					this.canCompute = false;
				}
			},
			deep: true
		}
	},

	computed: {
		conditionForShowFromAddress() {
			if (this.typeMeasurement === 1) {
				if (this.dataToSend.distanceKnown === false && !this.hideFromAddress) {
					return true;
				}
				return false;
			} else {
				return false;
			}
		},
		conditionForShowToAddress() {
			if (this.typeMeasurement === 1) {
				if (this.dataToSend.distanceKnown === false && !this.hideFromAddress) {
					return true;
				}
				return false;
			} else {
				return false;
			}
		},
		transportationMeanOptions() {
			let result = this.FormMenu(905);

			if (store.getCurrentProjectConfig().carbonModel === 2) {
				result = result.filter((option) => option.value < 41);
			}

			result = result.sort(function compare(a, b) {
				if (a.text.toLowerCase() < b.text.toLowerCase()) return -1;
				if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
				return 0;
			});

			return result;
		},
		productionPhaseOptions() {
			return [{ text: this.FormMSG(1987, 'Undefined (all phases)'), value: 0 }, ...this.FormMenu(PRODUCTION_PHASE_MENU)];
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit.toLowerCase();
		}
	},

	async created() {
		await this.getVehicules();
	},

	methods: {
		isInvalid() {
			this.$v.$touch();

			return this.$refs['transportTypSelectorCoTwo'].isInvalid() || this.$v.$invalid;
		},
		handleChangeNavigationDuration({ hourValid }) {
			this.dataToSend.navigationDuration = hourValid;
			this.emitChange();
		},
		handleTransportationTypeSelectorChange({ type, detail, additionalDetail, specialEquipment, isFreighter }) {
			const RES_VALUE_MENU_BOAT = [1, 2, 3, 22, 23, 24];

			this.dataToSend.type = type;
			this.dataToSend.subType = detail;
			this.dataToSend.additionalSubType = additionalDetail;
			this.dataToSend.specialEquipmentId = specialEquipment;
			this.dataToSend.isFreighter = isFreighter;

			this.transportTypeMapped = this.mappedTransportTypeToCarbonClapValue(
				this.dataToSend.type,
				this.dataToSend.subType,
				this.dataToSend.additionalSubType
			);

			this.emitChange();
		},
		handleChangeMaterialWeight() {
			this.emitChange();
		},
		handleChangeFuelConsumption() {
			this.emitChange();
		},
		handleChangeDistance() {
			this.emitChange();
		},
		handleChangeNumberVehicle() {
			this.emitChange();
		},
		handleChangeProductionPhase() {
			this.dataToSend.productionPhase = +this.dataToSend.productionPhase;
			this.emitChange();
		},
		async handleInputVehiculeId(payload) {
			this.dataToSend.vehiculeId = payload;

			const resultVehicule = await getVehicule(+payload);

			this.dataToSend.type = resultVehicule.type;
			this.dataToSend.subType = resultVehicule.subType;
			this.dataToSend.additionalSubType = resultVehicule.additionalSubType;

			this.emitChange();
		},
		async plannificationGoogle() {
			const fromAddressId = this.dataToSend.fromAddressId;
			const toAddressId = this.dataToSend.toAddressId;

			if (fromAddressId === 0 || toAddressId === 0) return;

			const from = await getAddress(fromAddressId);
			const to = await getAddress(toAddressId);

			const travel = getTravelModeFromTransportMean(this.transportTypeMapped);
			const dateTimeField = 'departureTime';

			try {
				const distancePayload = {
					from,
					to,
					...travel,
					avoidHighways: false,
					avoidTolls: false,
					[dateTimeField]: new Date().toISOString()
				};

				const res = await fetchDistance(distancePayload);

				const elem = res.rows[0].elements[0];
				const distanceInKm = elem.distance.value / 1000;

				this.dataToSend.km = distanceInKm;
			} catch (e) {
				this.durationEstimation = {
					status: 'error',
					message: e,
					estimation: null
				};
			}
		},
		async handleChangeToAddress(payload) {
			this.dataToSend.toAddressId = +payload.id;
			if (this.dataToSend.fromAddressId > 0 && this.dataToSend.toAddressId > 0 && !EXCEPTION_TO_COMPUTE.includes(this.transportTypeMapped)) {
				await this.plannificationGoogle(true);
			}

			this.emitChange();
		},
		handleDeleteToAddress() {
			this.dataToSend.toAddressId = 0;
			this.dataToSend.km = 0;

			this.emitChange();
		},
		async handleDeleteFromAddress() {
			this.dataToSend.fromAddressId = 0;
			this.dataToSend.km = 0;

			this.emitChange();
		},
		async handleChangeFromAddress(payload) {
			this.dataToSend.fromAddressId = +payload.id;

			if (this.dataToSend.fromAddressId > 0 && this.dataToSend.toAddressId > 0 && !EXCEPTION_TO_COMPUTE.includes(this.transportTypeMapped)) {
				await this.plannificationGoogle(true);
			}

			this.emitChange();
		},
		emitChange() {
			this.dataToSend.vehiculeId = +this.dataToSend.vehiculeId === 0 ? null : +this.dataToSend.vehiculeId;
			this.dataToSend.type = +this.dataToSend.type;
			this.dataToSend.reason = +this.dataToSend.reason;
			this.dataToSend.numberOfVehicule =
				!_.isNaN(+this.dataToSend.numberOfVehicule) && +this.dataToSend.numberOfVehicule > 0 ? +this.dataToSend.numberOfVehicule : 1;
			this.dataToSend.materialWeight = +this.dataToSend.materialWeight;
			this.dataToSend.km = +this.dataToSend.km;
			this.dataToSend.fromAddressId = +this.dataToSend.fromAddressId;
			this.dataToSend.toAddressId = +this.dataToSend.toAddressId;
			this.dataToSend.subType = +this.dataToSend.subType;
			this.dataToSend.additionalSubType = +this.dataToSend.additionalSubType;
			this.dataToSend.number = parseFloat(this.dataToSend.number);
			this.dataToSend.fuelConsumption = parseFloat(this.dataToSend.fuelConsumption);
			if (typeof this.dataToSend.navigationDuration === 'string') {
				this.dataToSend.navigationDuration = this.convertHoursToMinutes(this.dataToSend.navigationDuration);
			} else {
				this.dataToSend.navigationDuration = 0;
			}

			this.dataToSend.productionPhase = +this.dataToSend.productionPhase;
			this.dataToSend.specialEquipmentId = +this.dataToSend.specialEquipmentId;
			this.$emit('co-two-transport:change', this.dataToSend);
		},
		async getVehicules() {
			if (this.getMyVehicules === false) {
				this.vehicules = await getVehicules(true, 0);
			} else {
				this.vehicules = await getVehicules(false, store.userID());
			}
		}
	}
};
</script>

<style scoped></style>
