<template>
	<div>
		<b-row>
			<b-col sm="12" :md="!hideNumberOfBedroom ? 3 : 4" :lg="!hideNumberOfBedroom ? 3 : 4" :xl="!hideNumberOfBedroom ? 3 : 4">
				<b-form-group :label="FormMSG(15, 'Type')">
					<v-select
						label="text"
						v-model="$v.dataToSend.type.$model"
						:reduce="(option) => option.value"
						:options="accomodationTypeOptions"
						:placeholder="FormMSG(38, 'Select a type')"
						:clearable="false"
						:selectable="selectableTypeItem"
						:class="{ 'is-invalid': $v.dataToSend.type.$error }"
						:disabled="disableAll"
						@input="handleInputType"
					>
						<template #option="option">
							<div
								:class="`${
									(option.disabled && option.disabled === true) || (option.selectable && option.selectable === true)
										? 'text-bold'
										: 'text-color ml-3'
								}`"
							>
								{{ option.text }}
							</div>
						</template>
					</v-select>
					<div v-if="$v.dataToSend.type.$error" class="invalid-feedback">
						{{ FormMSG(425, 'Type is required') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col v-if="!hideNumberOfBedroom" sm="12" md="3" lg="3" xl="3">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(16, 'No. of bedrooms')" :state="!$v.dataToSend.numberBedroom.$error">
					<b-form-input
						v-model="$v.dataToSend.numberBedroom.$model"
						:placeholder="FormMSG(16, 'No. of bedrooms')"
						:state="!$v.dataToSend.numberBedroom.$error"
						:class="{ 'bg-color-grams-hair': disableForms || disableAll }"
						:disabled="disableForms || disableAll"
						@change="handleChangeNbBedroom"
					/>
					<div v-if="$v.dataToSend.numberBedroom.$error" class="invalid-feedback">
						{{ FormMSG(426, 'No. of bedrooms must greater than 0') }}
					</div>
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-125-px mr-3">
						<b-form-input
							v-model="$v.dataToSend.numberBedroom.$model"
							:placeholder="FormMSG(16, 'No. of bedrooms')"
							:state="!$v.dataToSend.numberBedroom.$error"
							:class="{ 'bg-color-grams-hair': disableForms || disableAll }"
							:disabled="disableForms || disableAll"
							@change="handleChangeNbBedroom"
						/>
						<div v-if="$v.dataToSend.numberBedroom.$error" class="invalid-feedback">
							{{ FormMSG(426, 'No. of bedrooms must greater than 0') }}
						</div>
					</div>
					<div class="d-flex align-items-center text-lowercase">
						<div class="fs-14">{{ FormMSG(16, 'No. of bedrooms') }}</div>
					</div>
				</div>
			</b-col>
			<b-col v-if="!hideNumberOfNight" sm="12" md="3" lg="3" xl="3">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(17, 'Number of nights')" :state="!$v.dataToSend.numberOfNight.$error">
					<b-form-input
						v-model="dataToSend.numberOfNight"
						:placeholder="FormMSG(17, 'Number of nights')"
						:state="!$v.dataToSend.numberOfNight.$error"
						:class="{ 'bg-color-grams-hair': disableForms || disableAll }"
						:disabled="disableForms || disableAll"
						@change="handleChangeNbNight"
					/>
					<div v-if="$v.dataToSend.numberOfNight.$error" class="invalid-feedback">
						{{ FormMSG(427, 'Number of nights must greader than 0') }}
					</div>
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-125-px mr-3">
						<b-form-input
							v-model="dataToSend.numberOfNight"
							:placeholder="FormMSG(17, 'Number of nights')"
							:state="!$v.dataToSend.numberOfNight.$error"
							:class="{ 'bg-color-grams-hair': disableForms || disableAll }"
							:disabled="disableForms || disableAll"
							@change="handleChangeNbNight"
						/>
						<div v-if="$v.dataToSend.numberOfNight.$error" class="invalid-feedback">
							{{ FormMSG(427, 'Number of nights must greader than 0') }}
						</div>
					</div>
					<div class="d-flex align-items-center text-lowercase">
						<div class="fs-14">{{ FormMSG(17, 'Number of nights') }}</div>
					</div>
				</div>
			</b-col>
			<b-col v-if="!hideTotalNumberOfNight" sm="12" md="3" lg="3" xl="3">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(19, 'Total number of nights')">
					<b-form-input v-model="totalNumberNights" class="bg-color-grams-hair" disabled />
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-125-px mr-3">
						<b-form-input v-model="totalNumberNights" class="bg-color-grams-hair" disabled />
					</div>
					<div class="d-flex align-items-center text-lowercase">
						<div class="fs-14">{{ FormMSG(19, 'Total number of nights') }}</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { noTilde } from '~utils';
import _ from 'lodash';

import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';

export default {
	name: 'CoTwoAccomodationForm',

	mixins: [languageMessages, validationMixin],

	props: {
		coTwoData: { type: Object, default: () => ({}), required: false },
		hideNumberOfBedroom: { type: Boolean, default: false, required: false },
		hideNumberOfNight: { type: Boolean, default: false, required: false },
		hideTotalNumberOfNight: { type: Boolean, default: false, required: false },
		disableForms: { type: Boolean, default: false, required: false },
		disableAll: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			dataToSend: {
				type: null,
				numberBedroom: 0,
				numberOfNight: 0
			}
		};
	},

	validations: {
		dataToSend: {
			type: { required },
			numberBedroom: { minValue: minValue(1) },
			numberOfNight: { minValue: minValue(1) }
		}
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.type = newVal.type;
					this.dataToSend.numberBedroom = newVal.numberBedroom;
					this.dataToSend.numberOfNight = newVal.numberOfNight;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		totalNumberNights() {
			return this.dataToSend.numberBedroom * this.dataToSend.numberOfNight;
		},
		hotelOptions() {
			let menus = this.FormMenu(1415);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 149,
					text: this.FormMSG(49, 'Hotel'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		houseOptions() {
			let menus = this.FormMenu(1417);

			menus.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return [
				{
					value: 150,
					text: this.FormMSG(50, 'House'),
					disabled: true
				},
				...menus.filter((menu) => menu.text !== 'not found')
			];
		},
		appartOptions() {
			let menus = this.FormMenu(1416);
			if (menus.length === 1) {
				menus[0].selectable = true;
			}

			return menus;
		},
		accomodationTypeOptions() {
			let menus = [];
			let headeroptions = [
				{
					value: 149,
					text: this.FormMSG(49, 'Hotel')
				},
				{
					value: 150,
					text: this.FormMSG(50, 'House')
				}
			];

			headeroptions.sort((a, b) => {
				if (noTilde(a.text.trim()).toLowerCase() < noTilde(b.text.trim()).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text.trim()).toLowerCase() > noTilde(b.text.trim()).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			let principalOptions = [...this.appartOptions];

			for (let i = 0; i < headeroptions.length; i++) {
				if (headeroptions[i].value === 149) {
					principalOptions.push(...this.hotelOptions);
				} else if (headeroptions[i].value === 150) {
					principalOptions.push(...this.houseOptions);
				}
			}

			menus = [...principalOptions];

			return menus;
		}
	},

	methods: {
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		handleChangeNbNight(payload) {
			this.emitChange();
		},
		handleChangeNbBedroom(payload) {
			this.emitChange();
		},
		handleInputType(payload) {
			this.emitChange();
		},
		emitChange() {
			this.dataToSend.numberOfNight = parseInt(this.dataToSend.numberOfNight);
			this.dataToSend.numberBedroom = parseInt(this.dataToSend.numberBedroom);

			this.$emit('co-two-accomodation:change', this.dataToSend);
		},
		selectableTypeItem(item) {
			return _.isNil(item.disabled) || (!_.isNil(item.disabled) && item.disabled === false);
		}
	}
};
</script>

<style scoped>
.divider-dotted {
	position: relative;
	margin: 8px 0 8px 0;
	border-top: none;
	border-left: none;
	border-right: none;
	border-bottom: 0.145rem dotted rgba(226, 230, 235, 0.85);
}

.text-bold {
	font-weight: 600;
	font-size: 1rem;
}

.text-color {
	font-size: 12px !important;
	color: rgba(6, 38, 62, 0.84) !important;
}

.text-empty-person {
	margin-top: -8px;
	color: #ea4e2c;
	font-size: 13px;
	letter-spacing: 0.05em;
	font-weight: 600;
}
</style>
