<template>
	<div>
		<b-row class="mt-2">
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(45, 'Number of rounds trips')">
					<b-form-input
						v-model="$v.dataToSend.number.$model"
						:disabled="disableAll"
						:placeholder="FormMSG(46, 'Enter number of rounds trips')"
						:state="getStateValidationField('dataToSend.number')"
						@change="emitChange"
					/>
					<div v-if="$v.dataToSend.number.$error && getStateValidationField('dataToSend.number') !== null" class="invalid-feedback">
						{{ FormMSG(687, 'The value must great than 0') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(47, 'Geographic area')">
					<b-form-select
						v-model="$v.dataToSend.type.$model"
						:disabled="disableAll"
						:options="geographicAreaOptions"
						:state="getStateValidationField('dataToSend.type')"
						@change="emitChange"
					/>
					<div v-if="$v.dataToSend.type.$error && getStateValidationField('dataToSend.number') !== null" class="invalid-feedback">
						{{ FormMSG(658, 'Please select a value') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { minValue, required } from 'vuelidate/lib/validators';
import { GEOGRAPHIC_AREA_MENU } from '../../../../../shared/constants';
import _ from 'lodash';

export default {
	name: 'CoTwoGroupTransportationForm',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		coTwoData: { type: Object, required: true },
		forCarbonTemplate: { type: Boolean, default: false, required: false },
		disableAll: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			dataToSend: {
				number: 1,
				type: 0
			}
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.number = parseFloat(newVal.number);
					this.dataToSend.type = newVal.type;
				}
			},
			immediate: true,
			deep: true
		}
	},

	validations: {
		dataToSend: {
			type: { minValue: minValue(1) },
			number: { minValue: minValue(1), required }
		}
	},

	computed: {
		geographicAreaOptions() {
			return [{ text: this.FormMSG(121, 'Please select ...'), value: 0 }, ...this.FormMenu(GEOGRAPHIC_AREA_MENU)];
		}
	},

	methods: {
		getStateValidationField(field) {
			const customCond = `this.$v.${field}.$error === false`;
			if (eval(customCond)) {
				return null;
			}

			return false;
		},
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		emitChange() {
			this.dataToSend.number = parseFloat(this.dataToSend.number);
			this.$emit('co-two-group-transportation-form:change', this.dataToSend);
		}
	}
};
</script>
