var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "fieldset",
        {
          staticClass: "my-0 py-0 pb-0",
          class: `${
            _vm.$screen.width <= 576
              ? "card-inside bg-color-white border-ligth-starlight"
              : ""
          }`,
        },
        [
          _vm.$screen.width <= 576
            ? _c(
                "legend",
                {
                  staticClass: "text-color-rhapsody-in-blue-2",
                  class: `${_vm.$screen.width <= 576 ? "card-inside" : ""}`,
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(698, "Generator")) +
                      "\n\t\t"
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { class: { "pt-1 pb-1": _vm.$screen.width <= 576 } },
            [
              _c(
                "b-row",
                [
                  _vm.$screen.width > 576
                    ? _c(
                        "b-col",
                        { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [_vm._v("   ")]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                790760987
                              ),
                            },
                            [
                              _c("b-form-input", {
                                staticClass: "bg-color-grams-hair",
                                attrs: {
                                  value: _vm.FormMSG(698, "Generator"),
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(636, "Fuel type") } },
                        [
                          _c("b-form-select", {
                            class: { "bg-color-grams-hair": _vm.disabled },
                            attrs: {
                              options: _vm.fuelTypeOptions,
                              disabled: _vm.disabled,
                            },
                            model: {
                              value: _vm.payload.generatorFuelType,
                              callback: function ($$v) {
                                _vm.$set(_vm.payload, "generatorFuelType", $$v)
                              },
                              expression: "payload.generatorFuelType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(637, "Volume used") } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "br-none",
                                class: { "bg-color-grams-hair": _vm.disabled },
                                attrs: { disabled: _vm.disabled },
                                model: {
                                  value: _vm.payload.generatorVolumeUsed,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payload,
                                      "generatorVolumeUsed",
                                      $$v
                                    )
                                  },
                                  expression: "payload.generatorVolumeUsed",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                { staticClass: "border-group-append" },
                                [
                                  _vm.has(_vm.payload.generatorPict) &&
                                  _vm.payload.generatorPict !== ""
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent bg-color-white text-color-blue-rinse",
                                          attrs: {
                                            type: "button",
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showPicture([
                                                _vm.payload.generatorPict,
                                              ])
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("Image"), {
                                            tag: "component",
                                            attrs: { size: 20 },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  !_vm.$isPwa()
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent text-color-rhapsody-in-blue",
                                          attrs: {
                                            type: "button",
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            click: _vm.openGeneratorPictModal,
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("Paperclip"), {
                                            tag: "component",
                                            attrs: { size: 20 },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.$isPwa()
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn-transparent text-color-rhapsody-in-blue",
                                          attrs: {
                                            type: "button",
                                            disabled: _vm.disabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.startMobilePwaCapture(
                                                "handleGeneratorPict"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("Paperclip"), {
                                            tag: "component",
                                            attrs: { size: 20 },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-north-wind input-group-text-bg-white text-color-white w-50-px",
                                    },
                                    [_vm._v(" L ")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("Capture", {
        attrs: {
          "custom-ref": _vm.rendRef("generator_pict"),
          "auto-crop-capture": "",
          "only-one-crop-mode": "",
          "parent-id": _vm.parentId,
          "parent-type": "location",
          "parent-sub-type": "generator_pict",
        },
        on: { change: _vm.handleGeneratorPict },
        model: {
          value: _vm.isGeneratorPictModalOpen,
          callback: function ($$v) {
            _vm.isGeneratorPictModalOpen = $$v
          },
          expression: "isGeneratorPictModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }