<template>
	<div>
		<b-row>
			<b-col sm="12" :md="4" :lg="4" :xl="4">
				<b-form-group :label="FormMSG(15, 'Reason')">
					<b-form-select
						v-model="dataToSend.type"
						:options="technicalProductionOptions"
						:placeholder="FormMSG(52, 'Please select')"
						:disabled="disableAll"
						@change="handleChangeType"
					/>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import _ from 'lodash';

export default {
	name: 'CoTwoTehnicalProductionForm',

	mixins: [languageMessages],

	props: {
		coTwoData: { type: Object, required: true },
		forCarbonTemplate: { type: Boolean, default: false, required: false },
		disableAll: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			dataToSend: {
				type: 0
			}
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) && !_.isNil(newVal)) {
					this.dataToSend.type = newVal.type;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		technicalProductionOptions() {
			return this.FormMenu(11010);
		}
	},

	methods: {
		handleChangeType() {
			this.emitChange();
		},
		emitChange() {
			this.$emit('co-two-tehnical-production:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
