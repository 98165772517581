var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _vm.$screen.width > 576
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(15, "Vegetarian/Vegan meal"),
                      },
                    },
                    [
                      _c("b-form-input", {
                        class: { "bg-color-grams-hair": _vm.disableAll },
                        attrs: { disabled: _vm.disableAll },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.dataToSend.mealVege,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "mealVege", $$v)
                          },
                          expression: "dataToSend.mealVege",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "d-flex mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "w-125-px mr-3" },
                      [
                        _c("b-form-input", {
                          class: { "bg-color-grams-hair": _vm.disableAll },
                          attrs: { disabled: _vm.disableAll },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.dataToSend.mealVege,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataToSend, "mealVege", $$v)
                            },
                            expression: "dataToSend.mealVege",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-items-center text-lowercase",
                      },
                      [
                        _c("div", { staticClass: "fs-14" }, [
                          _vm._v(
                            _vm._s(_vm.FormMSG(15, "Vegetarian/Vegan meal"))
                          ),
                        ]),
                      ]
                    ),
                  ]),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _vm.$screen.width > 576
                ? _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(16, "Red meat meals") } },
                    [
                      _c("b-form-input", {
                        class: { "bg-color-grams-hair": _vm.disableAll },
                        attrs: { disabled: _vm.disableAll },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.dataToSend.mealRed,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "mealRed", $$v)
                          },
                          expression: "dataToSend.mealRed",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "d-flex mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "w-125-px mr-3" },
                      [
                        _c("b-form-input", {
                          class: { "bg-color-grams-hair": _vm.disableAll },
                          attrs: { disabled: _vm.disableAll },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.dataToSend.mealRed,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataToSend, "mealRed", $$v)
                            },
                            expression: "dataToSend.mealRed",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-items-center text-lowercase",
                      },
                      [
                        _c("div", { staticClass: "fs-14" }, [
                          _vm._v(_vm._s(_vm.FormMSG(16, "Red meat meals"))),
                        ]),
                      ]
                    ),
                  ]),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _vm.$screen.width > 576
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: _vm.FormMSG(17, "Fish/white meat meals"),
                      },
                    },
                    [
                      _c("b-form-input", {
                        class: { "bg-color-grams-hair": _vm.disableAll },
                        attrs: { disabled: _vm.disableAll },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.dataToSend.mealWhite,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "mealWhite", $$v)
                          },
                          expression: "dataToSend.mealWhite",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "d-flex mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "w-125-px mr-3" },
                      [
                        _c("b-form-input", {
                          class: { "bg-color-grams-hair": _vm.disableAll },
                          attrs: { disabled: _vm.disableAll },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.dataToSend.mealWhite,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataToSend, "mealWhite", $$v)
                            },
                            expression: "dataToSend.mealWhite",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-items-center text-lowercase",
                      },
                      [
                        _c("div", { staticClass: "fs-14" }, [
                          _vm._v(
                            _vm._s(_vm.FormMSG(17, "Fish/white meat meals"))
                          ),
                        ]),
                      ]
                    ),
                  ]),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
            [
              _vm.$screen.width > 576
                ? _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(35, "Not specified") } },
                    [
                      _c("b-form-input", {
                        class: { "bg-color-grams-hair": _vm.disableAll },
                        attrs: { disabled: _vm.disableAll },
                        on: { change: _vm.handleChange },
                        model: {
                          value: _vm.dataToSend.mealNotSpecified,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "mealNotSpecified", $$v)
                          },
                          expression: "dataToSend.mealNotSpecified",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "d-flex mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "w-125-px mr-3" },
                      [
                        _c("b-form-input", {
                          class: { "bg-color-grams-hair": _vm.disableAll },
                          attrs: { disabled: _vm.disableAll },
                          on: { change: _vm.handleChange },
                          model: {
                            value: _vm.dataToSend.mealNotSpecified,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataToSend, "mealNotSpecified", $$v)
                            },
                            expression: "dataToSend.mealNotSpecified",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex align-items-center text-lowercase",
                      },
                      [
                        _c("div", { staticClass: "fs-14" }, [
                          _vm._v(_vm._s(_vm.FormMSG(35, "Not specified"))),
                        ]),
                      ]
                    ),
                  ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }