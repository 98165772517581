var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(15, "Vehicle type") } },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.transportationShootingOptions,
                      placeholder: _vm.FormMSG(16, "Please select ..."),
                      disabled: _vm.disableAll,
                    },
                    on: { change: _vm.handleChangeType },
                    model: {
                      value: _vm.dataToSend.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "type", $$v)
                      },
                      expression: "dataToSend.type",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          [1, 2, 3, 5].includes(_vm.dataToSend.type)
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(17, "Distance") } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            class: { "bg-color-grams-hair": _vm.disableAll },
                            attrs: { disabled: _vm.disableAll },
                            on: { change: _vm.handleChangeKm },
                            model: {
                              value: _vm.dataToSend.km,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToSend, "km", $$v)
                              },
                              expression: "dataToSend.km",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                {
                                  staticClass:
                                    "bg-color-north-wind text-color-white",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.distanceUnit) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          [4, 6, 7, 8].includes(_vm.dataToSend.type)
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: "3", lg: "3", xl: "3" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(18, "Flight duration") } },
                    [
                      _c("input-date-time-picker", {
                        attrs: {
                          label: _vm.FormMSG(18, "Flight duration"),
                          uiid: "flightDuration",
                          type: "text",
                          placeholder: "hh:mm",
                          item: _vm.dataToSend.number,
                          error: null,
                          nbHour: _vm.rangeHour[_vm.WORKTYPE.daily],
                          nbMinute: _vm.rangeMinute[_vm.WORKTYPE.daily],
                          fieldName: "number",
                          isEditable: () => !_vm.disableAll,
                          workType: _vm.WORKTYPE.daily,
                        },
                        on: { change: _vm.handleChangeFlightDuration },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }