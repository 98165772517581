<template>
	<div>
		<div>
			<include-elect :parent-id="locationId" :for-create="forCreate" :edit-data="elecData" :disabled="disableAll" @change="handleChangeElect" />
		</div>
		<div>
			<include-gas :parent-id="locationId" :for-create="forCreate" :edit-data="gasData" :disabled="disableAll" @change="handleChangeGas" />
		</div>
		<div>
			<include-water :parent-id="locationId" :for-create="forCreate" :edit-data="waterData" :disabled="disableAll" @change="handleChangeWater" />
		</div>
		<div>
			<!-- <generator :parent-id="locationId" :for-create="forCreate" :edit-data="generatorData" :disabled="disableAll" @change="handleChangeGenerator" /> -->
			<fuel-oil :parent-id="locationId" :for-create="forCreate" :edit-data="generatorData" :disabled="disableAll" @change="handleChangeGenerator" />
		</div>
	</div>
</template>

<script>
import IncludeElect from '@/components/LocationService/_Elect';
import IncludeGas from '@/components/LocationService/_Gas';
import IncludeWater from '@/components/LocationService/_Water';
import Generator from '@/components/LocationService/Generator';
import FuelOil from '@/components/LocationService/FuelOil';
import { getLocation } from '@/cruds/locations.crud';

export default {
	name: 'CoTwoLocationForm',

	components: {
		IncludeElect,
		IncludeGas,
		IncludeWater,
		Generator,
		FuelOil
	},

	props: {
		coTwoData: { type: Object, default: () => ({}), required: false },
		disableAll: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			locationId: 0,
			elecData: {
				elecMeterEnd: 0,
				elecMeterEndPict: '',
				elecMeterStart: 0,
				elecMeterStartPict: '',
				elecSupplier: 0,
				consumptionElectricity: 0
			},
			gasData: {
				gasMeterEnd: 0,
				gasMeterEndPict: '',
				gasMeterStart: 0,
				gasMeterStartPict: '',
				gasSupplier: 0,
				consumptionGas: 0
			},
			waterData: {
				waterMeterEnd: 0,
				waterMeterEndPict: '',
				waterMeterStart: 0,
				waterMeterStartPict: '',
				waterSupplier: 0,
				consumptionWater: 0
			},
			generatorData: {
				fuelOilVolumeUsed: 0,
				generatorPict: '',
				paidByProduction: false
			},
			forCreate: true
		};
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				this.elecData.elecMeterEnd = newVal.elecMeterEnd;
				this.elecData.elecMeterEndPict = '';
				this.elecData.elecMeterStart = newVal.elecMeterStart;
				this.elecData.elecMeterStartPict = '';
				this.elecData.elecSupplier = newVal.elecSupplier;
				this.elecData.consumptionElectricity = newVal.consumptionElectricity;

				this.gasData.gasMeterEnd = newVal.gasMeterEnd;
				this.gasData.gasMeterEndPict = '';
				this.gasData.gasMeterStart = newVal.gasMeterStart;
				this.gasData.gasMeterStartPict = '';
				this.gasData.gasSupplier = newVal.gasSupplier;
				this.gasData.consumptionGas = newVal.consumptionGas;

				this.waterData.waterMeterEnd = newVal.waterMeterEnd;
				this.waterData.waterMeterEndPict = '';
				this.waterData.waterMeterStart = newVal.waterMeterStart;
				this.waterData.waterMeterStartPict = '';
				this.waterData.waterSupplier = newVal.waterSupplier;
				this.waterData.consumptionWater = newVal.consumptionWater;

				this.generatorData.fuelOilVolumeUsed = newVal.fuelOilVolumeUsed;
				this.generatorData.generatorPict = '';
				this.generatorData.paidByProduction = newVal.paidByProduction;
			},
			immediate: true,
			deep: true
		}
	},

	methods: {
		handleChangeGenerator(payload) {
			// console.log(this.generatorData)
			this.generatorData.fuelOilVolumeUsed = payload.fuelOilVolumeUsed;
			this.generatorData.generatorPict = payload.generatorPict;
			this.generatorData.paidByProduction = payload.paidByProduction;

			this.emitChange();
		},
		handleChangeWater(payload) {
			this.waterData.waterMeterEnd = payload.waterMeterEnd;
			this.waterData.waterMeterEndPict = payload.waterMeterEndPict;
			this.waterData.waterMeterStart = payload.waterMeterStart;
			this.waterData.waterMeterStartPict = payload.waterMeterStartPict;
			this.waterData.waterSupplier = payload.waterSupplier;
			this.waterData.consumptionWater = payload.consumptionWater;

			this.emitChange();
		},
		handleChangeGas(payload) {
			this.gasData.gasMeterEnd = payload.gasMeterEnd;
			this.gasData.gasMeterEndPict = payload.gasMeterEndPict;
			this.gasData.gasMeterStart = payload.gasMeterStart;
			this.gasData.gasMeterStartPict = payload.gasMeterStartPict;
			this.gasData.gasSupplier = payload.gasSupplier;
			this.gasData.consumptionGas = payload.consumptionGas;

			this.emitChange();
		},
		handleChangeElect(payload) {
			this.elecData.elecMeterEnd = payload.elecMeterEnd;
			this.elecData.elecMeterEndPict = payload.elecMeterEndPict;
			this.elecData.elecMeterStart = payload.elecMeterStart;
			this.elecData.elecMeterStartPict = payload.elecMeterStartPict;
			this.elecData.elecSupplier = payload.elecSupplier;
			this.elecData.consumptionElectricity = payload.consumptionElectricity;

			this.emitChange();
		},
		emitChange() {
			this.$emit('co-two-location:change', {
				elecData: this.elecData,
				gasData: this.gasData,
				waterData: this.waterData,
				generatorData: this.generatorData
			});
		},
		async getLocation() {
			// this.currentData = await getLocation(306);
		}
	}
};
</script>

<style scoped></style>
