var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "fieldset",
        {
          staticClass: "my-0 py-0 pb-0",
          class: `${
            _vm.$screen.width <= 576
              ? "card-inside bg-color-white border-ligth-starlight"
              : ""
          }`,
        },
        [
          _vm.$screen.width <= 576
            ? _c(
                "legend",
                {
                  staticClass: "text-color-rhapsody-in-blue-2",
                  class: `${_vm.$screen.width <= 576 ? "card-inside" : ""}`,
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(_vm.FormMSG(653, "Electricity")) +
                      "\n\t\t"
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { class: { "pt-1 pb-1": _vm.$screen.width <= 576 } },
            [
              _c(
                "b-row",
                { staticClass: "d-flex align-items" },
                [
                  _vm.$screen.width > 576
                    ? _c(
                        "b-col",
                        { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(632, "Type") } },
                            [
                              _c("b-form-input", {
                                staticClass: "bg-color-grams-hair",
                                attrs: {
                                  value: _vm.FormMSG(653, "Electricity"),
                                  disabled: "",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
                    [
                      _c("supplier-energy-consumption", {
                        attrs: {
                          label: _vm.FormMSG(633, "Supplier"),
                          "dialog-title": _vm.FormMSG(
                            253,
                            "Electricity supplier"
                          ),
                          "supplier-type": "electricity",
                          "label-add-supplier": _vm.FormMSG(
                            198,
                            "New electricity supplier"
                          ),
                          "supplier-id": _vm.payload.elecSupplier,
                          disabled: _vm.disabled,
                        },
                        on: {
                          "supplier-energy:change":
                            _vm.handleSupplierSelectorChange,
                          "supplier-energy:unselect":
                            _vm.handleSupplierUnselect,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(634, "Meter start") } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "br-none",
                                class: { "bg-color-grams-hair": _vm.disabled },
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  input: _vm.computeElectricityConsumption,
                                },
                                model: {
                                  value: _vm.payload.elecMeterStart,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payload, "elecMeterStart", $$v)
                                  },
                                  expression: "payload.elecMeterStart",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-white input-group-text-bg-white",
                                    },
                                    [
                                      _c("div", { staticClass: "d-flex" }, [
                                        _vm.has(
                                          _vm.payload.elecMeterStartPict
                                        ) &&
                                        _vm.payload.elecMeterStartPict !== ""
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-blue-rinse",
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showPicture([
                                                      _vm.payload
                                                        .elecMeterStartPict,
                                                    ])
                                                  },
                                                },
                                              },
                                              [
                                                _c(_vm.getLucideIcon("Image"), {
                                                  tag: "component",
                                                  attrs: { size: 20 },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.$isPwa()
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue",
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click:
                                                    _vm.openElectPicModalStart,
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "Paperclip"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: { size: 20 },
                                                  }
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.$isPwa()
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue",
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.startMobilePwaCapture(
                                                      "handleElectPicStart"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "Paperclip"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: { size: 20 },
                                                  }
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(635, "Meter end") } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "br-none",
                                class: { "bg-color-grams-hair": _vm.disabled },
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  input: _vm.computeElectricityConsumption,
                                },
                                model: {
                                  value: _vm.payload.elecMeterEnd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.payload, "elecMeterEnd", $$v)
                                  },
                                  expression: "payload.elecMeterEnd",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-white input-group-text-bg-white",
                                    },
                                    [
                                      _c("div", { staticClass: "d-flex" }, [
                                        _vm.has(_vm.payload.elecMeterEndPict) &&
                                        _vm.payload.elecMeterEndPict !== ""
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-blue-rinse",
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showPicture([
                                                      _vm.payload
                                                        .elecMeterEndPict,
                                                    ])
                                                  },
                                                },
                                              },
                                              [
                                                _c(_vm.getLucideIcon("Image"), {
                                                  tag: "component",
                                                  attrs: { size: 20 },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        !_vm.$isPwa()
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue",
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click:
                                                    _vm.openElectPicModalEnd,
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "Paperclip"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: { size: 20 },
                                                  }
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.$isPwa()
                                          ? _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn-transparent text-color-rhapsody-in-blue",
                                                attrs: {
                                                  disabled: _vm.disabled,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.startMobilePwaCapture(
                                                      "handleElectPicEnd"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  _vm.getLucideIcon(
                                                    "Paperclip"
                                                  ),
                                                  {
                                                    tag: "component",
                                                    attrs: { size: 20 },
                                                  }
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isInvalid
                            ? _c("small", { staticStyle: { color: "red" } }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.FormMSG(
                                      1101,
                                      "Meter end must be greater than meter start"
                                    )
                                  ) + " "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } },
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: _vm.FormMSG(75, "Consumption") } },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                class: { "bg-color-grams-hair": _vm.disabled },
                                attrs: { disabled: _vm.disabled },
                                on: { input: _vm.computeElectricityEnd },
                                model: {
                                  value: _vm.payload.consumptionElectricity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.payload,
                                      "consumptionElectricity",
                                      $$v
                                    )
                                  },
                                  expression: "payload.consumptionElectricity",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass:
                                        "bg-color-north-wind text-color-white",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(259, "kWh")) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("Capture", {
        attrs: {
          "custom-ref": _vm.rendRef("pic_elec_system_start"),
          "auto-crop-capture": "",
          parentId: _vm.parentId,
          parentType: "location",
          parentSubType: "location_elect_start",
        },
        on: { change: _vm.handleElectPicStart },
        model: {
          value: _vm.isElectPicStartModalOpen,
          callback: function ($$v) {
            _vm.isElectPicStartModalOpen = $$v
          },
          expression: "isElectPicStartModalOpen",
        },
      }),
      _c("Capture", {
        attrs: {
          "custom-ref": _vm.rendRef("pic_elec_system_end"),
          "auto-crop-capture": "",
          parentId: _vm.parentId,
          parentType: "location",
          parentSubType: "location_elect_end",
        },
        on: { change: _vm.handleElectPicEnd },
        model: {
          value: _vm.isElectPicEndModalOpen,
          callback: function ($$v) {
            _vm.isElectPicEndModalOpen = $$v
          },
          expression: "isElectPicEndModalOpen",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }