var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: 4, lg: 4, xl: 4 } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: { label: _vm.FormMSG(10, "Vehicle type") },
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.vehicleType,
                      placeholder: _vm.FormMSG(11, "Please select"),
                      disabled: _vm.disableAll,
                    },
                    on: { change: _vm.handleChangeType },
                    model: {
                      value: _vm.dataToSend.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "type", $$v)
                      },
                      expression: "dataToSend.type",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isKm
            ? _c(
                "b-col",
                { attrs: { sm: "12", md: 4, lg: 4, xl: 4 } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(12, "Fuel consumption") } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              isDisable: false,
                              addUnit: true,
                              enableExpend: false,
                              "is-for-number": false,
                            },
                            on: { change: _vm.emitChange },
                            model: {
                              value: _vm.dataToSend.fuelConsumption,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToSend, "fuelConsumption", $$v)
                              },
                              expression: "dataToSend.fuelConsumption",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                {
                                  staticClass:
                                    "bg-color-north-wind text-color-white",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(14, "L")) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "b-col",
                { attrs: { sm: "12", md: 4, lg: 4, xl: 4 } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(13, "Distance") } },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              isDisable: false,
                              addUnit: true,
                              enableExpend: false,
                              "is-for-number": false,
                            },
                            on: { change: _vm.emitChange },
                            model: {
                              value: _vm.dataToSend.km,
                              callback: function ($$v) {
                                _vm.$set(_vm.dataToSend, "km", $$v)
                              },
                              expression: "dataToSend.km",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                {
                                  staticClass:
                                    "bg-color-north-wind text-color-white",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(15, "Km")) +
                                      "\n\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }