<template>
	<div>
		<fieldset class="my-0 py-0 pb-0" :class="`${$screen.width <= 576 ? 'card-inside bg-color-white border-ligth-starlight' : ''}`">
			<legend v-if="$screen.width <= 576" :class="`${$screen.width <= 576 ? 'card-inside' : ''}`" class="text-color-rhapsody-in-blue-2">
				{{ FormMSG(698, 'Generator') }}
			</legend>
			<div :class="{ 'pt-1 pb-1': $screen.width <= 576 }">
				<b-row>
					<b-col v-if="$screen.width > 576" sm="12" md="2" lg="2" xl="2">
						<b-form-group>
							<template #label> &nbsp; </template>
							<b-form-input :value="FormMSG(698, 'Generator')" class="bg-color-grams-hair" disabled />
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(636, 'Fuel type')">
							<b-form-select
								v-model="payload.generatorFuelType"
								:options="fuelTypeOptions"
								:disabled="disabled"
								:class="{ 'bg-color-grams-hair': disabled }"
							/>
						</b-form-group>
					</b-col>
					<b-col sm="12" md="4" lg="4" xl="4">
						<b-form-group :label="FormMSG(637, 'Volume used')">
							<b-input-group>
								<b-form-input
									v-model="payload.generatorVolumeUsed"
									class="br-none"
									:disabled="disabled"
									:class="{ 'bg-color-grams-hair': disabled }"
								/>
								<b-input-group-append class="border-group-append">
									<button
										v-if="has(payload.generatorPict) && payload.generatorPict !== ''"
										type="button"
										class="btn-transparent bg-color-white text-color-blue-rinse"
										:disabled="disabled"
										@click="showPicture([payload.generatorPict])"
									>
										<component :is="getLucideIcon('Image')" :size="20" />
									</button>
									<button
										v-if="!$isPwa()"
										type="button"
										class="btn-transparent text-color-rhapsody-in-blue"
										:disabled="disabled"
										@click="openGeneratorPictModal"
									>
										<component :is="getLucideIcon('Paperclip')" :size="20" />
									</button>
									<button
										v-if="$isPwa()"
										type="button"
										class="btn-transparent text-color-rhapsody-in-blue"
										:disabled="disabled"
										@click="startMobilePwaCapture('handleGeneratorPict')"
									>
										<component :is="getLucideIcon('Paperclip')" :size="20" />
									</button>
									<b-input-group-text class="bg-color-north-wind input-group-text-bg-white text-color-white w-50-px"> L </b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
			</div>
		</fieldset>

		<Capture
			v-model="isGeneratorPictModalOpen"
			:custom-ref="rendRef('generator_pict')"
			auto-crop-capture
			only-one-crop-mode
			:parent-id="parentId"
			parent-type="location"
			parent-sub-type="generator_pict"
			@change="handleGeneratorPict"
		/>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import meterHelperMixin from './meterHelperMixin';

export default {
	name: 'Generator',

	components: {
		Capture: () => import('@/components/CaptureModule/Main')
	},

	mixins: [languageMessages, globalMixin, meterHelperMixin],

	props: {
		forCreate: { type: Boolean, default: false, required: false },
		disabled: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			currentLocation: {},
			payload: {
				generatorVolumeUsed: 0,
				generatorFuelType: 0,
				generatorPict: '',
				paidByProduction: false
			},
			isGeneratorPictModalOpen: false
		};
	},

	watch: {
		editData: {
			handler(newVal) {
				this.payload = {
					generatorVolumeUsed: newVal.generatorVolumeUsed,
					generatorFuelType: newVal.generatorFuelType,
					generatorPict: newVal.generatorPict,
					paidByProduction: newVal.paidByProduction
				};
			},
			immediate: true
		},
		payload: {
			handler(e) {
				this.$emit('change', {
					...e,
					generatorVolumeUsed: parseInt(e.generatorVolumeUsed),
					generatorFuelType: parseInt(e.generatorFuelType)
				});
			},
			deep: true
		}
	},

	computed: {
		fuelTypeOptions() {
			return this.FormMenu(1402);
		},
		generatorUnit() {
			if (this.payload.generatorFuelType === 0 || this.payload.generatorFuelType === 2) {
				return this.FormMSG(1265, 'L');
			} else if (this.payload.generatorFuelType === 1) {
				return this.FormMSG(1621, 'm3');
			} else if (this.payload.generatorFuelType === 3) {
				return this.FormMSG(1622, 'kg');
			}
		}
	},

	methods: {
		openGeneratorPictModal() {
			this.isGeneratorPictModalOpen = true;
		},
		handleGeneratorPict(imgs) {
			this.payload.generatorPict = imgs[0];
			this.save('generatorPict', imgs[0]);
		}
	}
};
</script>

<style scoped></style>
