<template>
	<div>
		<b-row v-if="dataToSend.type > 0">
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(267, 'Energy type')" :state="!$v.dataToSend.subType.$error">
					<b-form-select
						v-model="$v.dataToSend.subType.$model"
						:options="energyTypes"
						:state="!$v.dataToSend.subType.$error"
						disabled
						@change="handleChangeSubType"
					/>
					<div v-if="$v.dataToSend.subType.$error" class="invalid-feedback">
						{{ FormMSG(300, 'Energy type is required') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(275, 'Consumption')" :state="!$v.consumption.$error">
					<b-input-group>
						<b-form-input
							v-model="$v.consumption.$model"
							:disabled="disableAll"
							:class="{ 'bg-color-grams-hair': disableAll }"
							@change="handleChangeConsumption"
							:state="!$v.consumption.$error"
						/>
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ unitConsumption }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
					<div v-if="$v.consumption.$error" class="invalid-feedback">
						{{ FormMSG(300, 'Energy type is required') }}
					</div>
				</b-form-group>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { validationMixin } from 'vuelidate';
import { minValue } from 'vuelidate/lib/validators';
import { REASON_ENERGY_TYPE_MENU, ENERGY_TYPE_FOR_LOCATION_PROMISE, ENERGY_TYPE_MENU, CAR_LODGE_ENERGY_TYPE_MENU } from '@/shared/constants';
import _ from 'lodash';
import { SPE_GENERATOR_ENERGY_TYPE_MENU } from '../../../../../shared/constants';
import { getSpecialEquipment } from '@/cruds/special-equipment.crud';

export default {
	name: 'CoTwoEnergyForm',

	props: {
		coTwoData: { type: Object, default: () => ({}), required: false },
		disableAll: { type: Boolean, default: false, required: false }
	},

	mixins: [languageMessages, validationMixin],

	data() {
		return {
			dataToSend: {
				type: 0,
				locationId: null,
				subType: 0,
				liter: 0,
				kwh: 0,
				mcube: 0,
				waterVolumeUsed: 0,
				number: 0
			},

			consumption: 0,
			locations: [],
			forInit: true
		};
	},

	validations: {
		dataToSend: {
			subType: { minValue: minValue(1) }
		},
		consumption: { minValue: minValue(1) }
	},

	watch: {
		coTwoData: {
			async handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					let subType = 0;

					if (newVal.specialEquipmentId > 0) {
						let resultGetSpecialEquipment = await getSpecialEquipment(newVal.specialEquipmentId);
						subType = resultGetSpecialEquipment.energyType;
					}

					this.dataToSend.type = newVal.type;
					this.dataToSend.locationId = newVal.locationId === 0 || newVal.locationId === null ? null : '' + newVal.locationId;
					this.dataToSend.subType = subType;

					this.addValueToConsumption(newVal);
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		unitConsumption() {
			if (this.dataToSend.subType === 0) {
				return this.FormMSG(283, 'L');
			} else if (this.dataToSend.subType === 1 || this.dataToSend.subType === 2) {
				return this.FormMSG(956, 'L');
			} else if (this.dataToSend.subType === 3) {
				return this.FormMSG(957, 'm3');
			}

			return this.FormMSG(15789, 'kg');
		},
		energyTypes() {
			return [{ value: 0, text: this.FormMSG(1475, 'Please select a generator') }, ...this.FormMenu(SPE_GENERATOR_ENERGY_TYPE_MENU)];
		},
		reasonTypes() {
			return [{ value: 0, text: this.FormMSG(263, 'Please select ...') }, ...this.FormMenu(REASON_ENERGY_TYPE_MENU)];
		}
	},

	methods: {
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		addValueToConsumption(val) {
			if (this.dataToSend.subType === 1 || this.dataToSend.subType === 2) {
				this.consumption = val.liter;
			} else if (this.dataToSend.subType === 3) {
				this.consumption = val.mcube;
			} else if (this.dataToSend.subType === 4) {
				this.consumption = val.number;
			}
		},
		handleChangeSubType() {
			this.emitChange();
		},
		async handleChangeConsumption() {
			this.dataToSend.number = 0;
			this.dataToSend.mcube = 0;
			this.dataToSend.liter = 0;

			if (this.dataToSend.subType === 1 || this.dataToSend.subType === 2) {
				this.dataToSend.liter = +this.consumption;
			} else if (this.dataToSend.subType === 3) {
				this.dataToSend.mcube = +this.consumption;
			} else if (this.dataToSend.subType === 4) {
				this.dataToSend.number = +this.consumption;
			}

			this.emitChange();
		},
		emitChange() {
			this.forInit = false;
			// this.dataToSend.locationId = +this.dataToSend.locationId;
			this.$emit('co-two-energy:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
