var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(45, "Number of rounds trips") } },
                [
                  _c("b-form-input", {
                    attrs: {
                      disabled: _vm.disableAll,
                      placeholder: _vm.FormMSG(
                        46,
                        "Enter number of rounds trips"
                      ),
                      state: _vm.getStateValidationField("dataToSend.number"),
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.$v.dataToSend.number.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.dataToSend.number, "$model", $$v)
                      },
                      expression: "$v.dataToSend.number.$model",
                    },
                  }),
                  _vm.$v.dataToSend.number.$error &&
                  _vm.getStateValidationField("dataToSend.number") !== null
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(
                              _vm.FormMSG(687, "The value must great than 0")
                            ) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(47, "Geographic area") } },
                [
                  _c("b-form-select", {
                    attrs: {
                      disabled: _vm.disableAll,
                      options: _vm.geographicAreaOptions,
                      state: _vm.getStateValidationField("dataToSend.type"),
                    },
                    on: { change: _vm.emitChange },
                    model: {
                      value: _vm.$v.dataToSend.type.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.dataToSend.type, "$model", $$v)
                      },
                      expression: "$v.dataToSend.type.$model",
                    },
                  }),
                  _vm.$v.dataToSend.type.$error &&
                  _vm.getStateValidationField("dataToSend.number") !== null
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(658, "Please select a value")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }