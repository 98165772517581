<template>
	<div>
		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(411, 'Size')" :state="!$v.dataToSend.type.$error">
					<b-form-select v-model="$v.dataToSend.type.$model" :options="carSizeTypes" :disabled="disableAll" :state="!$v.dataToSend.type.$error" />
					<div v-if="$v.dataToSend.type.$error" class="invalid-feedback">
						{{ FormMSG(413, 'Size is required') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="3" lg="3" xl="3">
				<b-form-group v-if="$screen.width > 576" :label="FormMSG(412, 'Distance')" :state="!$v.consumption.$error">
					<b-input-group>
						<b-form-input
							v-model="$v.consumption.$model"
							@change="handleChangeConsumption"
							:disabled="disableAll"
							:state="!$v.consumption.$error"
						/>
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ distanceUnit }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
					<div v-if="$v.consumption.$error" class="invalid-feedback">
						{{ FormMSG(261, 'Distance must greater than 0') }}
					</div>
				</b-form-group>
				<div v-else class="d-flex mt-3">
					<div class="w-90 mr-3">
						<b-form-group :label="FormMSG(480, 'Consumption')" :state="!$v.consumption.$error">
							<b-form-input
								v-model="$v.consumption.$model"
								:state="!$v.consumption.$error"
								:disabled="disableAll"
								:class="{ 'bg-color-grams-hair': disableAll }"
								@change="handleChangeConsumption"
							/>
							<div v-if="$v.consumption.$error" class="invalid-feedback">
								{{ FormMSG(261, 'Distance must greater than 0') }}
							</div>
						</b-form-group>
					</div>
					<div class="d-flex align-items-center pt-3 text-capitalize">
						<div class="fs-20 fw-700">
							{{ distanceUnit }}
						</div>
					</div>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import { validationMixin } from 'vuelidate';
import { minValue } from 'vuelidate/lib/validators';
import { TRANSPORTATION_MEAN_MENU } from '@/shared/constants';

import _ from 'lodash';

export default {
	name: 'CoTwoTransportCarLodgesForm',

	mixins: [languageMessages, validationMixin],

	props: {
		coTwoData: { type: Object, required: true },
		disableAll: { type: Boolean, default: false, required: false }
	},

	data() {
		return {
			dataToSend: {
				type: 6,
				km: 0
			},

			consumption: 0
		};
	},

	validations: {
		dataToSend: {
			type: { minValue: minValue(1) }
		},
		consumption: { minValue: minValue(1) }
	},

	watch: {
		coTwoData: {
			handler(newVal) {
				if (JSON.stringify(newVal) !== '{}' && !_.isNil(newVal)) {
					this.dataToSend.type = newVal.type;
					this.dataToSend.km = newVal.km;
					this.consumption = newVal.km;
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		distanceUnit() {
			return store.state.myProfile.distanceUnit.toLowerCase();
		},
		carSizeTypes() {
			return this.FormMenu(1432);
		}
	},

	methods: {
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		handleChangeConsumption() {
			this.dataToSend.km = +this.consumption;

			this.emitChange();
		},
		emitChange() {
			this.$emit('co-two-transport-car-lodges:change', this.dataToSend);
		}
	}
};
</script>

<style scoped></style>
