var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: 4, lg: 4, xl: 4 } },
            [
              _c(
                "b-form-group",
                {
                  staticClass: "mb-0",
                  attrs: { label: _vm.FormMSG(318, "Production rooms") },
                },
                [
                  _c("v-select", {
                    class: {
                      "is-invalid": _vm.$v.dataToSend.specialEquipmentId.$error,
                    },
                    attrs: {
                      options: _vm.listProductionRooms,
                      reduce: (option) => +option.id,
                      label: "name",
                      placeholder: _vm.FormMSG(52, "Please select"),
                      disabled: _vm.disableAll,
                    },
                    on: { input: _vm.handleChangeType },
                    model: {
                      value: _vm.$v.dataToSend.specialEquipmentId.$model,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.$v.dataToSend.specialEquipmentId,
                          "$model",
                          $$v
                        )
                      },
                      expression: "$v.dataToSend.specialEquipmentId.$model",
                    },
                  }),
                  _vm.$v.dataToSend.specialEquipmentId.$error
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(589, "Please select trailer")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }